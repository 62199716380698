import { isEmpty } from 'lodash';

export const getTabList = (selectedDataTab) => [
  {
    text: 'Сводный рейтинг',
    name: 'svodnyj-rejting',
    active: isEmpty(selectedDataTab),
    data: {
      chartInfo: [
        {
          helpInfo: {
            progressBlock: [],
            textBlock: [
              {
                title: 'Формула расчета',
                subTitle: '',
                formula: 'ПЭМПКi = 30%(30%ИДim + 50%АДМim + 20%ДСim) + 35%(СЗ) + 10%(Бim) + 15%(П) + 10%(РТ)',
                text: `
                  <b>i</b> – страна-партнер<br
                  <b>ИДi</b> – Исполнительская дисциплина<br>
                  <b>ИДim</b> – среднее значение показателя ИДi по итогам последнего и предпоследнего заседаний МПК<br>
                  <b>АДМi</b> – активность МПК<br>
                  <b>АДМim</b> – среднее значение показателя АДМi по итогам двух предыдущих лет.<br>
                  <b>ДСi</b> – деятельность делового совета<br>
                  <b>ДСim</b> – среднее значение показателя ДСi по итогам двух предыдущих лет<br>
                  <b>СЗ</b> – выполнение сквозных задач<br>
                  <b>Бi</b> – снятие барьеров в торговле<br>
                  <b>Бim</b> – среднее значение показателя Бi по итогам прошедших двух лет.<br>
                  <b>П</b> – реализация двусторонних проектов<br>
                  <b>РТ</b> – уровень развития торговли<br>
                `,
              },
            ],
          },
          title: 'Сводный рейтинг эффективности МПК с дружественными странами',
          targetData: 'summaryRating',
          countriesСounted: true,
          targetValue: 1,
          view: 0,
          graphInfo: [],
        },
      ],
    },
  },
  {
    text: 'Уровень интенсивности МПК',
    name: 'uroven-intensivnosti-mpk',
    active: false,
    data: {
      title: 'Уровень интенсивности МПК',
      chartInfo: [
        {
          helpInfo: {
            progressBlock: [],
            textBlock: [
              {
                title: 'Вес компонента',
                subTitle: '0,3',
                formula: '',
                text: '',
              },
              {
                title: 'Критерий оценки',
                subTitle: 'Доля выполненных поручений в общем объеме по итогам двух последних заседаний МПК',
                formula: '',
                text: '',
              },
              {
                title: 'Формула расчета',
                subTitle: '',
                formula: 'ИДi = (ВПi / ОПi) / (Д + 1)',
                text: `
                  <b>i</b> – страна-партнер;<br>
                  <b>ВП</b> – количество выполненных поручений;<br>
                  <b>ОП </b>– общее количество поручений;<br>
                  <b>Д</b> – давность проведения последнего заседания МПК в годах<br>
                  <p>Расчет итогового значения ИДim по итогам двух последних заседаний МПК ведется на основе среднего значения ИДi по итогам предпоследнего и последнего заседаний МПК<br>
                  <span class="formula">ИДim = (ИДi предпоследнего заседания МПК + ИДi последнего заседания МПК) / 2</span></p>
                `,
              },
            ],
          },
          targetData: 'executiveDisciplineRating',
          title: 'Исполнительская дисциплина',
          name: 'Рейтинг стран по компоненту ИД',
          graphInfo: [],
        },
        {
          helpInfo: {
            progressBlock: [],
            textBlock: [
              {
                title: 'Вес компонента',
                subTitle: '0,5',
                formula: '',
                text: '',
              },
              {
                title: 'Критерий оценки',
                subTitle:
                  'Количество состоявшихся мероприятий в рамках МПК в межсессионный период с учетом значимости типов мероприятий',
                formula: '',
                text: '',
              },
              {
                title: 'Формула расчета',
                subTitle: '',
                formula: 'АДМi = К_МПКi * Кр + К_Вi * Kp + (К_РГi / О_РГi) * Kp',
                text: `
                  <b>i</b> – страна-партнер;<br>
                  <b>К_МПК</b> – количество заседаний МПК (Кр = 0,5);<br>
                  <b>К_В</b> – количество встреч сопредседателей МПК (Kp = 0,25);<br>
                  <b>К_РГ</b> – количество заседаний рабочих групп (Kp = 0,15);<br>
                  <b>О_РГ</b> – общее количество рабочих групп.<br>

                  Расчет итогового значения АДМ m за 2 года ведется на основе среднего значения АДМ по итогам двух предыдущих лет<br>
                  <p><span class="formula"> АДМim = (АДМ1 + АДМ2) / 2 где: </span></p>
                  AДМ1 - значение показателя АДМi за предыдущий год; <br>
                  AДМ2 - значение показателя АДМi за год предшествующий предыдущему году. <br>
                `,
              },
            ],
          },
          targetData: 'mpkActivityRating',
          title: 'Активность деятельности МПК',
          name: 'Рейтинг стран по компоненту АД',
          graphInfo: [],
        },
        {
          helpInfo: {
            progressBlock: [],
            textBlock: [
              {
                title: 'Вес компонента',
                subTitle: '0,2',
                formula: '',
                text: '',
              },
              {
                title: 'Критерий оценки',
                subTitle: 'Оценивает количество состоявшихся мероприятий по линии Делового совета',
                formula: '',
                text: '',
              },
              {
                title: 'Формула расчета',
                subTitle: '',
                formula: 'ДСi = К_Зi * Кр + К_РЧ * Kp + (К_РП / О_РП) * Kp',
                text: `
                  <b>i</b> – страна-партнер;<br>
                  <b>К_З</b> – количество заседаний Делового совета (Кр = 0,5);<br>
                  <b>К_РЧ</b> – количество заседаний российской части Делового совета (Kp = 0,3);<br>
                  <b>К_РП</b> – количество выполненных рекомендаций Правительства по итогу заседания МПК (Kp = 0,2);<br>
                  <b>О_РП</b> – общее количество рекомендаций Правительства по итогу заседания МПК.<br>

                  Расчет итогового значения LCim за 2 года ведется на основе среднего значения ДС по итогам двух предыдущих лет<br>
                  <p><span class="formula"> ДСim = (ДС1 + ДС2) / 2 где: </span></p>
                  ДС1 - значение показателя ДСi за предыдущий год; <br>
                  ДС2 - значение показателя ДСi за год предшествующий предыдущему году. <br>
                `,
              },
            ],
          },
          targetData: 'businessCouncilRating',
          title: 'Деятельность делового совета',
          name: 'Рейтинг стран по компоненту ДС',
          graphInfo: [],
        },
      ],
    },
  },
  {
    text: 'Сквозные задачи',
    name: 'skvoznye-zadachi',
    active: false,
    data: {
      title: 'Сквозные задачи',
      chartInfo: [
        {
          helpInfo: {
            progressBlock: [],
            textBlock: [
              {
                title: 'Вес компонента',
                subTitle: '0,35',
                formula: '',
                text: '',
              },
              {
                title: 'Критерий оценки',
                subTitle: 'Доля выполненных сквозных задач (подзадач)',
                formula: '',
                text: '',
              },
              {
                title: 'Формула расчета',
                subTitle: '',
                formula: 'СЗi = (РЗi / ОЗi) * K',
                text: `
                  <b>i</b> – страна-партнер;<br>
                  <b>РЗ</b> – количество реализованных подзадач;<br>
                  <b>ОЗ</b> – общее количество подзадач, поставленных для проработки со страной партнером, по всем задачам (отраслям);<br>
                  <b>К</b> – корректирующий коэффициент.<br>
                `,
              },
            ],
            tablesBlock: {
              title: 'Корректирующие коэффициенты',
              tables: [
                {
                  firstColumnName: 'Количество подзадач',
                  secondColumnName: 'Коэффициент',
                  rows: [
                    {
                      first: 'Более 30',
                      second: '1',
                    },
                    {
                      first: 'От 20 до 30',
                      second: '0,8',
                    },
                    {
                      first: 'От 10 до 20',
                      second: '0,5',
                    },
                    {
                      first: 'Менее 10',
                      second: '0,1',
                    },
                  ],
                },
              ],
            },
          },
          title: 'Выполнение сквозных задач',
          name: 'Рейтинг стран по компоненту СЗ',
          targetData: 'crossCuttingTasksRating',
          countriesСounted: '',
          targetValue: '',
          graphInfo: [],
        },
      ],
    },
  },
  {
    text: 'Объем снятых барьеров',
    name: 'obem-snyatyh-barerov',
    active: false,
    data: {
      title: 'Объем снятых барьеров',
      chartInfo: [
        {
          helpInfo: {
            progressBlock: [],
            textBlock: [
              {
                title: 'Вес компонента',
                subTitle: '0,1',
                formula: '',
                text: '',
              },
              {
                title: 'Критерий оценки',
                subTitle: 'Результаты проведенной работы в рамках МПК по снятию барьеров для российского экспорта',
                formula: '',
                text: '',
              },
              {
                title: 'Формула расчета',
                subTitle: '',
                formula: 'Бi = Q_i / (2Q_max ) + E_i / (2E_max )',
                text: `
                  <b>i</b> – страна-партнер;<br>
                  <b>Qi</b> – количество выявленных барьеров по стране и рассмотренных в рамках МПК иных проблемных вопросов, связанных с доступом российского экспорта;<br>
                  <b>Qmax</b> – максимальное количество выявленных барьеров и рассмотренных в рамках МПК проблемных вопросов, связанных с доступом российского экспорта;<br>
                  <b>Ei</b> – количество устранённых барьеров по стране и решенных в рамках МПК проблемных вопросов, связанных с доступом российского экспорта;<br>
                  <b>Emax</b> – максимальное количество устраненных барьеров и решенных в рамках МПК проблемных вопросов, связанных с доступом российского экспорта.<br>
                  Расчет итогового значения Бm за 2 года ведется на основе среднего значения Бm по итогам двух предыдущих лет<br>
                  <p><span class="formula">Бim = (Б1 + Б2) / 2</span> где:</p>
                  Б1 - Значение показателя ДБi за предыдущий год; <br>
                  Б2 - Значение показателя ДБi за год, предшествующий предыдущему году; <br>
                `,
              },
            ],
          },
          title: 'Снятие барьеров в торговле',
          name: 'Рейтинг стран по компоненту Б',
          targetData: 'tradeBarriersRemovalRating',
          countriesСounted: '',
          targetValue: '',
          graphInfo: [],
        },
      ],
    },
  },
  {
    text: 'Двусторонние проекты',
    name: 'dvustoronnie-proekty',
    active: false,
    data: {
      title: 'Двусторонние проекты',
      chartInfo: [
        {
          helpInfo: {
            progressBlock: [
              {
                text: 'Реализованные проекты',
                value: '0,7',
              },
              {
                text: 'Проекты в стадии подготовки/реализации',
                value: '0,3',
              },
            ],
            textBlock: [
              {
                title: 'Вес компонента',
                subTitle: '0,15',
                formula: '',
                text: '',
              },
              {
                title: 'Критерий оценки',
                subTitle:
                  'Качество структуры портфеля проектов исходя из стадии их реализации, видов проектов и объема инвестиций',
                formula: '',
                text: '',
              },
              {
                title: 'Формула расчета',
                subTitle: '',
                formula: 'Пi = (SUMr (KВПi *KОИi) / КПri * 0,7 + SUMk (KРПi * KВПi *KОИi) / КПki * 0,3) * Kp',
                text: `
                  <b>i</b> – страна-партнер;<br>
                  <b>r</b> – порядковый номер реализованного проекта по стране;<br>
                  <b>k</b> – порядковый номер проекта по стране (в стадии подготовки/реализации);<br>
                  <b>SUM</b> – сумма значений по каждому проекту расчетов в скобках;<br>
                  <b>КРП</b> – корректирующий коэффициент степени реализации проекта;<br>
                  <b>KВП</b> – корректирующий коэффициент вида проекта;<br>
                  <b>KОИ</b> – корректирующий коэффициент объема инвестиций проекта;<br>
                  <b>КП(r,k)</b> – общее количество проектов (реализованных, нереализованных);<br>
                  <b>Kp</b> – корректирующий коэффициент количества проектов.<br>
                `,
              },
            ],
            tablesBlock: {
              title: '',
              tables: [
                {
                  firstColumnName: 'Количество проектов',
                  secondColumnName: 'Коэффициент',
                  rows: [
                    {
                      first: 'Более 10',
                      second: '1',
                    },
                    {
                      first: 'От 5 до 10',
                      second: '0,3',
                    },
                    {
                      first: 'Менее 5',
                      second: '0,1',
                    },
                  ],
                },
                {
                  firstColumnName: 'Стадия реализации проекта',
                  secondColumnName: 'КРП',
                  rows: [
                    {
                      first: 'Подготовка к реализации',
                      second: '0,5',
                    },
                    {
                      first: 'Проект в стадии реализации',
                      second: '0,8',
                    },
                  ],
                },
                {
                  firstColumnName: 'Вид проекта',
                  secondColumnName: 'КВП',
                  rows: [
                    {
                      first: 'Иностранные проекты в России',
                      second: '1',
                    },
                    {
                      first: 'Российские проекты за рубежом: ЕРС-контракты (в т.ч под ключ)',
                      second: '0,7',
                    },
                    {
                      first: 'Другие проекты',
                      second: '0,3',
                    },
                  ],
                },
                {
                  firstColumnName: 'Объем инвестиций',
                  secondColumnName: 'КОИ',
                  rows: [
                    {
                      first: 'Более 100 млн. долл. США',
                      second: '1',
                    },
                    {
                      first: 'От 50 до 100 млн. долл. США',
                      second: '0,9',
                    },
                    {
                      first: 'От 1 до 50 млн. долл. США',
                      second: '0,8',
                    },
                    {
                      first: 'Менее 1 млн. долл. США',
                      second: '0,7',
                    },
                  ],
                },
              ],
            },
          },
          title: 'Реализация двусторонних проектов',
          name: 'Рейтинг стран по компоненту П',
          targetData: 'bilateralProjectsRating',
          countriesСounted: '',
          targetValue: '',
          graphInfo: [],
        },
      ],
    },
  },
  {
    text: 'Развитие торговли',
    name: 'razvitie-torgovli',
    active: false,
    data: {
      title: 'Развитие торговли',
      chartInfo: [
        {
          title: 'Уровень развития торговли',
          name: 'Рейтинг стран по компоненту РТ',
          helpInfo: {
            progressBlock: [],
            textBlock: [
              {
                title: 'Вес компонента',
                subTitle: '0,1',
                formula: '',
                text: '',
              },
              {
                title: 'Критерий оценки',
                subTitle:
                  'Динамика торговли России со страной за последние два года с учетом темпа прироста и индекса физического объема',
                formula: '',
                text: '',
              },
              {
                title: 'Формула расчета',
                subTitle: '',
                formula: 'РТi = ((П_Эi +ИФ_Эi) / 2 + (П_Иi + ИФ_Иi) / 2 ) / 2',
                text: `
                  <b>i</b> – страна-партнер;<br>
                  <b>П_Э</b> – темп прироста экспорта России в страну;<br>
                  <b>П_И</b> – темп прироста импорта России из страны;<br>
                  <b>ИФ_Э</b> – индекс физического объема экспорта России в страну;<br>
                  <b>ИФ_И</b> – индекс физического объема импорта России из страны<br>
                `,
              },
            ],
          },
          targetData: 'tradeDevelopmentRating',
          countriesСounted: '',
          targetValue: '',
          graphInfo: [],
        },
      ],
    },
  },
];
