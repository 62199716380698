<template>
  <div class="category">
    <div class="category__flex">
      <p class="category__title">Вес компонента</p>
      <span class="category__value">{{ weight }}</span>
    </div>
    <div class="category__flex">
      <p class="category__title">Значение</p>
      <span class="category__value">{{ value }}</span>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      weight: {
        type: Number,
        required: true,
      },
      value: {
        type: Number,
        required: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .category {
    display: flex;
    margin-bottom: 24px;
    gap: 40px;

    &__flex {
      display: flex;
      gap: 12px;
    }

    &__title {
      color: $base;
      font: $fira-20-M;
    }

    &__value {
      background-image: linear-gradient(90deg, $blue 0%, $green 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font: $fira-20-M;
    }
  }
</style>
