import axios from 'axios';

export default {
  getYearList(countryId) {
    return axios(`/api/mpkbarrierquestion/getyearlist/${countryId}`);
  },

  get(params) {
    return axios.get('/api/mpkbarrierquestion/search', {
      params
    });
  },

  create(data) {
    return axios.post('/api/mpkbarrierquestion/create', data);
  },

  update(questionId, data) {
    return axios.put(`/api/mpkbarrierquestion/update/${questionId}`, data);
  },

  delete(questionId) {
    return axios.delete(`/api/mpkbarrierquestion/delete/${questionId}`);
  },
}
