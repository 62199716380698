<template>
  <div class="content raitingChart">
    <div class="container-page big">
      <h2 class="raitingChart__title" v-if="dataChart.title">
        {{ dataChart.title }}
      </h2>

      <div class="raitingChart__container" v-for="(item, index) in chart" :key="index">
        <div class="raitingChart__container__subTitleBlock">
          <span class="raitingChart__container__subTitleBlock__title" v-if="item.title">
            {{ item.title }}
          </span>

          <Tooltip
            v-if="item?.helpInfo"
            :triggers="['click']"
            modContent="width-780"
            class="tooltip-component--mobile-big"
          >
            <div
              class="raitingChart__container__subTitleBlock__helpButton__progressBlock"
              v-if="item.helpInfo?.progressBlock?.length"
            >
              <span
                class="raitingChart__container__subTitleBlock__helpButton__progressBlock__text"
                v-for="help in item.helpInfo.progressBlock"
                :key="help"
              >
                {{ help.text }}
                <span class="raitingChart__container__subTitleBlock__helpButton__progressBlock__text__value">{{
                  help.value
                }}</span>
              </span>
            </div>
            <div
              class="raitingChart__container__subTitleBlock__helpButton__textBlock"
              v-for="help in item.helpInfo.textBlock"
              :key="help"
            >
              <span
                class="raitingChart__container__subTitleBlock__helpButton__textBlock__title"
                v-if="help.title"
                v-html="help.title"
              ></span>
              <span
                class="raitingChart__container__subTitleBlock__helpButton__textBlock__formula"
                v-if="help.formula"
                v-html="help.formula"
              ></span>
              <span
                class="raitingChart__container__subTitleBlock__helpButton__textBlock__subtitle"
                v-if="help.subTitle"
                v-html="help.subTitle"
              ></span>
              <span
                class="raitingChart__container__subTitleBlock__helpButton__textBlock__text"
                v-if="help.text"
                v-html="help.text"
              ></span>
            </div>

            <div
              class="raitingChart__container__subTitleBlock__helpButton__tablesBlock"
              v-if="item?.helpInfo?.tablesBlock?.tables?.length"
            >
              <span
                class="raitingChart__container__subTitleBlock__helpButton__tablesBlock__title"
                v-if="item.helpInfo.tablesBlock.title"
                >{{ item.helpInfo.tablesBlock.title }}</span
              >
              <div class="raitingChart__container__subTitleBlock__helpButton__tablesBlock__tables">
                <table
                  cellpadding
                  class="raitingChart__container__subTitleBlock__helpButton__tablesBlock__tables__table"
                  v-for="table in item.helpInfo.tablesBlock.tables"
                  :key="table"
                >
                  <thead class="raitingChart__container__subTitleBlock__helpButton__tablesBlock__tables__table__thead">
                    <tr
                      class="raitingChart__container__subTitleBlock__helpButton__tablesBlock__tables__table__thead__tr"
                    >
                      <th
                        class="raitingChart__container__subTitleBlock__helpButton__tablesBlock__tables__table__thead__tr__th"
                        v-if="table.firstColumnName"
                      >
                        {{ table.firstColumnName }}
                      </th>
                      <th
                        class="raitingChart__container__subTitleBlock__helpButton__tablesBlock__tables__table__thead__tr__th raitingChart__container__subTitleBlock__helpButton__tablesBlock__tables__table__thead__tr__th_second"
                        v-if="table.secondColumnName"
                      >
                        {{ table.secondColumnName }}
                      </th>
                    </tr>
                  </thead>
                  <tbody class="raitingChart__container__subTitleBlock__helpButton__tablesBlock__tables__table__tbody">
                    <tr
                      class="raitingChart__container__subTitleBlock__helpButton__tablesBlock__tables__table__tbody__tr"
                      v-for="row in table.rows"
                      :key="row"
                    >
                      <td
                        class="raitingChart__container__subTitleBlock__helpButton__tablesBlock__tables__table__tbody__tr__td"
                      >
                        {{ row.first }}
                      </td>
                      <td
                        class="raitingChart__container__subTitleBlock__helpButton__tablesBlock__tables__table__tbody__tr__td raitingChart__container__subTitleBlock__helpButton__tablesBlock__tables__table__tbody__tr__td_blue raitingChart__container__subTitleBlock__helpButton__tablesBlock__tables__table__tbody__tr__td_second"
                      >
                        {{ row.second }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Tooltip>
        </div>

        <span class="raitingChart__container__nameRaiting" v-if="item.name">{{ item.name }}</span>
        <div class="raitingChart__container__infoBlock" v-if="item.countriesСounted || item.targetValue">
          <span class="raitingChart__container__infoBlock__text" v-if="item.countriesСounted">
            Всего стран
            <span class="raitingChart__container__infoBlock__text_blue">
              {{ dataChart.chartInfo[index].graphInfo.length }}
            </span>
          </span>
          <span class="raitingChart__container__infoBlock__text">
            Целевое значение
            <span class="raitingChart__container__infoBlock__text_blue" v-if="item.targetValue">
              {{ item.targetValue }}
            </span>
          </span>
        </div>
        <div class="raitingChart__container__chartBlock">
          <HorizontalBarCustom
            maxWidth="916px"
            :scalePitch="1"
            :chartData="chartData(item)"
            :labels="chartLabels(item)"
            :selected="chartSelected(item)"
          />
        </div>
        <div class="raitingChart__container__controlBlock">
          <button
            class="raitingChart__container__controlBlock__button more"
            v-if="item.graphInfo.length != dataChart.chartInfo[index].graphInfo.length"
            @click="setLimit(index, '+')"
          >
            Показать еще
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="raitingChart__container__controlBlock__button__icon"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M8 3.33334V12.6667"
                stroke="#01A39D"
                stroke-width="1.5"
                stroke-linecap="square"
                stroke-linejoin="round"
              />
              <path
                d="M3.33301 8H12.6663"
                stroke="#01A39D"
                stroke-width="1.5"
                stroke-linecap="square"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <button
            v-if="item.graphInfo.length != dataChart.chartInfo[index].graphInfo.length"
            @click="setLimit(index, 'all')"
            class="raitingChart__container__controlBlock__button all"
          >
            Показать все
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="raitingChart__container__controlBlock__button__icon"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M8 3.33334V12.6667"
                stroke="#01A39D"
                stroke-width="1.5"
                stroke-linecap="square"
                stroke-linejoin="round"
              />
              <path
                d="M3.33301 8H12.6663"
                stroke="#01A39D"
                stroke-width="1.5"
                stroke-linecap="square"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <button
            v-if="item.graphInfo.length > preLimit"
            @click="setLimit(index, 'reset')"
            class="raitingChart__container__controlBlock__button hide"
          >
            Скрыть
            <svg
              class="raitingChart__container__controlBlock__button__icon"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M3.3335 8H12.6668"
                stroke="#DC3545"
                stroke-width="1.5"
                stroke-linecap="square"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Tooltip from '@/common/ui/Tooltip.vue';

  import HorizontalBarCustom from './HorizontalBarCustom';
  export default {
    name: 'RaitingChart',
    components: { HorizontalBarCustom, Tooltip },

    props: {
      dataChart: {
        type: Object,
        default() {
          return {};
        },
      },
    },

    data() {
      return {
        chart: [],
        preLimit: 20,
        paginations: {},
      };
    },

    mounted() {
      this.defaultLimit();
    },

    watch: {
      dataChart: function () {
        this.defaultLimit();
      },
    },

    methods: {
      chartData(chart) {
        return chart.graphInfo.map((data) => data.value.toFixed(3));
      },
      chartLabels(chart) {
        return chart.graphInfo.map((data) => {
          return `
                  <span class ='count ${data.selectFirstPreLimit ? 'blue' : ''}'>
                      ${data.index}
                  </span>
                  <span class=' text'>
                      ${data.name.trim()}
                  </span>`;
        });
      },

      chartSelected(chart) {
        return chart.graphInfo.map((data) => data.selectFound);
      },

      defaultLimit() {
        if (this.dataChart?.chartInfo) {
          this.chart = JSON.parse(JSON.stringify(this.dataChart.chartInfo)).map((itm) => {
            const limit = this.paginations[itm.targetData] || this.preLimit;

            let index = 0;

            let limitSelected = 0;
            // Находим выделенный бар и если он дальше лимита "20", то изменяем лимит отрисовки первых элементов до выделенного элемента
            while (index < itm.graphInfo.length) {
              const data = itm.graphInfo[index];

              if (data.selectFound) {
                limitSelected = index + 1 > limit ? index + 1 : 0;
                break;
              }
              index++;
            }

            if (limit >= itm.graphInfo.length) {
              return itm;
            } else if (limit < itm.graphInfo.length) {
              itm.graphInfo = itm.graphInfo.slice(0, limitSelected || limit);
              return itm;
            } else {
              return itm;
            }
          });
        }

        setTimeout(() => {
          const selectBar = document.querySelector('.horizontalBarCustom__barBlock.selectedFound');
          if (selectBar) {
            const selectBarPosition = selectBar.getBoundingClientRect();

            window.scrollTo(0, selectBarPosition.top - window.innerHeight / 2);
          }
        }, 100);
      },

      setLimit(index, mod) {
        if (this.dataChart?.chartInfo) {
          let calc;
          const currData = this.dataChart.chartInfo[index];
          if (mod) {
            if (mod == '+') calc = this.chart[index].graphInfo.length + 10;
            else if (mod == '-') calc = this.chart[index].graphInfo.length - 10;
            else if (mod == 'reset') calc = this.preLimit;
            else if (mod == 'all') calc = currData.graphInfo.length;

            this.paginations[currData.targetData] = calc;

            this.chart[index].graphInfo = currData.graphInfo.slice(0, calc);
          }
        }
      },
    },
  };
</script>

<style lang="scss">
  .raitingChart {
    &__title {
      font: $fira-20-M;
      margin: 0 0 20px 0;
    }

    &__container {
      width: 100%;
      display: flex;
      flex-direction: column;

      &:not(:last-child) {
        margin: 0 0 60px 0;
      }

      &__subTitleBlock {
        display: flex;
        align-items: flex-start;
        margin: 0 0 16px 0;
        position: relative;

        &__title {
          color: $base;
          font: $fira-20-M;
          margin: 0 8px 0 0;
        }

        &__helpButton {
          &__progressBlock {
            display: flex;
            justify-content: space-between;
            max-width: 758px;
            width: 100%;
            gap: 10px;
            flex-wrap: wrap;
            margin: 0 0 32px 0;

            &__text {
              color: $base;
              font: $fira-20-M;
              display: flex;
              gap: 12px;

              &__value {
                font: $fira-20-M;
                background: linear-gradient(90deg, $blue 0%, $green 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                @media (max-width: $laptop) {
                  font: $fira-16-M;
                }
              }

              @media (max-width: $laptop) {
                font: $fira-16-M;
              }

              @media (max-width: 620px) {
                flex-direction: column;
                gap: 4px 0;
              }
            }
          }

          &__textBlock {
            display: flex;
            flex-direction: column;
            widows: 100%;
            max-width: 695px;

            &__title {
              color: $base;
              font: $fira-16-M;
              margin: 0 0 4px 0;
            }

            &__subtitle {
              color: $base;
              font: $fira-16;
            }

            &__formula {
              color: $green;
              font: $fira-16;
              margin: 0 0 8px 0;
            }

            &__text {
              color: $light-grey-12;
              font: $fira-16;

              b {
                color: $light-grey-12;
                font: $fira-14;
              }

              p {
                margin: 8px 0 0 0;
                color: $light-grey-12;
                font: $fira-14;

                .formula {
                  color: $green;
                  font: $fira-14;
                }
              }
            }

            &:not(:last-child) {
              margin: 0 0 12px 0;
            }
          }

          &__tablesBlock {
            margin: 20px 0 0 0;
            max-width: 872px;
            width: 100%;

            &__title {
              color: $base;
              font: $fira-16-M;
              margin: 0 0 16px 0;
              display: block;
            }

            &__tables {
              width: 100%;
              display: flex;
              justify-content: space-between;
              gap: 32px 10px;
              flex-wrap: wrap;

              &__table {
                table-layout: fixed;
                flex: 49%;

                &__thead {
                  &__tr {
                    border-bottom: 2px solid $green;

                    &__th {
                      color: $dark-grey-1;
                      font: $fira-14-M;
                      padding: 0;
                      max-width: 200px;
                      padding: 0 0 16px 0;

                      &_second {
                        padding: 0 0 16px 16px;
                      }

                      @media (max-width: $laptop-2) {
                        font: $fira-12-M;
                      }

                      @media (max-width: 640px) {
                        font: $fira-14-M;
                      }
                    }
                  }
                }

                &__tbody {
                  &__tr {
                    &__td {
                      color: $base;
                      font: $fira-14;
                      max-width: 200px;
                      padding: 12px 16px 12px 0;
                      border-right: 1px solid #e7e7e7;
                      border-bottom: 1px solid #e7e7e7;

                      &_blue {
                        color: $green;
                      }

                      &_second {
                        padding: 12px 16px 12px 16px;
                      }

                      @media (max-width: $laptop-2) {
                        font: $fira-12;
                      }

                      @media (max-width: 640px) {
                        font: $fira-14;
                      }
                    }
                  }
                }

                @media (max-width: 640px) {
                  flex: 100%;
                }
              }
            }
          }
        }
      }

      &__nameRaiting {
        margin: 0 0 16px 0;
        color: $blue;
        font: $fira-16-M;
      }

      &__infoBlock {
        display: flex;
        align-items: center;
        gap: 20px;
        margin: 0 0 16px 0;

        &__text {
          color: $base;
          font: $fira-20-M;

          &_blue {
            font: $fira-20-M;
            background: linear-gradient(90deg, $blue 0%, $green 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            @media (max-width: 500px) {
              font: $fira-16-M;
            }

            @media (max-width: 425px) {
              font: $fira-14-M;
            }
          }

          @media (max-width: 500px) {
            font: $fira-16-M;
          }

          @media (max-width: 425px) {
            font: $fira-14-M;
          }
        }

        @media (max-width: 425px) {
          gap: 10px;
        }
      }

      &__controlBlock {
        display: flex;
        align-items: center;
        max-width: 372px;
        width: 100%;

        margin: 16px 0 0 0;

        &__button {
          color: $green;
          font: $fira-16;
          background-color: transparent;
          border: none;
          outline: none;
          cursor: pointer;
          padding: 0;
          transition: all ease 0.3s;

          &:hover {
            color: #007b75;
          }

          &.more {
            margin: 0 16px 0 0;
          }

          &.all {
            margin: 0 40px 0 0;

            @media (max-width: 410px) {
              margin: 0 0 0 0;
            }
          }

          &.hide {
            color: $red;

            &:hover {
              color: #b40d1d;
            }
          }
        }

        @media (max-width: 410px) {
          gap: 10px 0;
          flex-wrap: wrap;
        }
      }
    }
  }
</style>
