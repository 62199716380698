<template>
  <div class="mpkRating__general">
    <div class="mpkRating__general__navigationBlock">
      <Swiper @swiper="onSwiper" slidesPerView="auto" :spaceBetween="8" :grabCursor="true">
        <SwiperSlide class="mpkRating__general__navigationBlock__slide" v-for="item in tabList" :key="item.name">
          <ButtonComponent
            :class="`mpkRating__general__navigationBlock__button
          ${item.active && 'active'}`"
            mod="border-ellipse"
            @click="changeTab(item)"
          >
            {{ item.text }}
          </ButtonComponent>
        </SwiperSlide>
      </Swiper>
    </div>
    <div class="mpkRating__general__blockChart">
      <RaitingChart :dataChart="selectedTab" v-if="selectedTab?.chartInfo" />
    </div>
  </div>
</template>

<script>
  import { isEmpty } from 'lodash';
  import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue.js';
  import 'swiper/swiper-bundle.css';

  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';

  import API from '../api';
  import { getTabList } from '../utils/getTabList';
  import RaitingChart from './RaitingChart';

  export default {
    name: 'General',
    components: {
      ButtonComponent,
      RaitingChart,
      Swiper,
      SwiperSlide,
    },

    props: {
      selectTab: {
        type: Object,
        default: () => {},
      },
    },

    data() {
      return {
        tabList: getTabList(this.selectedDataTab),
        selectedTab: {},
        ratings: {},
        // Дублируем данные с пришедшего пропса, чтобы была возможность внести изменения
        selectedDataTab: this.selectTab,
      };
    },

    created() {
      this.loadData();
    },

    methods: {
      loadData() {
        API.getMpkRatingForCountries().then((response) => {
          // Сохраняем пришедший набор данных, чтобы в дальнейшем была возможность им манипулировать
          this.ratings = response?.data?.ratings ?? {};
          this.dataProcessor();
        });
      },

      dataProcessor() {
        this.tabList.forEach((item) => {
          if (item?.data?.chartInfo) {
            item.data.chartInfo.forEach((chart) => {
              if (chart.targetData) {
                let data = [];
                Object.keys(this.ratings).forEach((key) => {
                  data.push({
                    name: key,
                    index: this.ratings[key][chart.targetData + 'Position'],
                    value: this.ratings[key][chart.targetData],
                    selectFirstPreLimit: false,
                    selectFound:
                      this.selectedDataTab?.country == key && this.selectedDataTab?.nameChart == chart?.targetData,
                  });
                });

                data = data.sort((a, b) => a.index - b.index);
                // Отмечаю первые двадцать элементов, после сортировки

                for (let i = 0; i < 20; i++) {
                  data[i].selectFirstPreLimit = true;
                }

                chart.graphInfo = data;
              }
            });
          }

          if (!isEmpty(this.selectedDataTab)) {
            item.active = false;

            if (this.selectedDataTab.nameTab == item.name) {
              item.active = true;
            }
          }

          if (item.active) {
            this.selectedTab = item.data;
          }
        });
      },

      changeTab(tab) {
        this.tabList.forEach((itm) => {
          if (itm.name == tab.name) {
            if (!isEmpty(this.selectedDataTab)) {
              // Проверяем перед переключением таба есть ли выделенный бар, если есть, то очищаем переменную выделенного таба, запускаем функцию по пере сборке основного набора данных, чтобы снять выделение
              this.selectedDataTab = {};
              this.dataProcessor();
            }

            itm.active = true;
            this.selectedTab = itm.data;
          } else {
            itm.active = false;
          }
        });
      },
    },

    setup() {
      const onSwiper = (swiper) => {
        window.swiper = swiper;
      };
      return {
        onSwiper,
      };
    },
  };
</script>

<style lang="scss" scoped>
  .mpkRating {
    &__general {
      &__navigationBlock {
        margin: 0 0 40px 0;

        &__slide {
          width: auto !important;
        }
      }
    }
  }
</style>
