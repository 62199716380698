<template>
  <div class="cochairsEvents">
    <template v-for="index in [...Array(2).keys()]">
      <div v-if="cochairs[index]" class="cochairsEvents__item" :key="index">
        <div
          :class="`cochairsEvents__item__imageBlock ${checkAvatar(index)}`"
          :style="{
            backgroundImage: cochairs[index]?.participant?.picture && `url(${cochairs[index]?.participant?.picture})`,
          }"
        >
          <div class="cochairsEvents__item__imageBlock__countryBlock">
            <img
              v-if="data?.country?.picture"
              :src="getFlag(index)"
              alt="Флаг"
              class="cochairsEvents__item__imageBlock__countryBlock__image"
            />
          </div>
        </div>
        <div class="cochairsEvents__item__infoBlock">
          <span v-if="cochairs[index]?.participant?.name" class="cochairsEvents__item__infoBlock__title">
            {{ cochairs[index].participant.name }}
          </span>
          <span v-if="cochairs[index]?.participant?.position" class="cochairsEvents__item__infoBlock__description">
            {{ cochairs[index].participant.position }}
          </span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import Constants from '@/common/constants';
  export default {
    props: {
      data: {
        type: Object,
        default: () => {},
      },
    },

    data() {
      return {
        cochairs: this.data.mpkParticipants,
        rusCode: Constants.countryRussiaId,
      };
    },

    methods: {
      getFlag(index) {
        return this.cochairs[index].participant.countryId == this.rusCode
          ? require('@/assets/images/flag_ru.png')
          : this.data.country.picture;
      },

      checkAvatar(index) {
        return !this.cochairs[index]?.participant?.picture ? 'noImage' : '';
      },
    },
  };
</script>

<style lang="scss" scoped>
  .cochairsEvents {
    border-radius: 4px;
    background: rgba(37, 192, 90, 0.1);
    padding: 20px 16px;
    display: flex;
    gap: 16px;
    width: 100%;
    margin: 0 0 40px 0;
    flex-wrap: wrap;

    @media (max-width: 650px) {
      margin: 0 0 25px 0;
    }

    &__item {
      display: flex;
      gap: 12px;
      flex: 1;

      &__imageBlock {
        min-width: 64px;
        height: 64px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 100%;

        &.noImage {
          background: #e2e2e2;
        }

        &__countryBlock {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          border: 1px solid $grey;
          background-color: $white;
          z-index: 1;

          &__image {
            width: 16px;
            object-fit: cover;
          }
        }
      }

      &__infoBlock {
        display: flex;
        flex-direction: column;
        gap: 4px;

        &__title {
          color: $base;
          font: $fira-20-M;
        }

        &__description {
          color: $light-grey-12;
          font: $fira-14;
        }
      }

      @media screen and (max-width: 920px) {
        flex: 100%;
      }
    }
  }
</style>
