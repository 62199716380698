<template>
  <div class="mpkRating__detailed__removingExports" v-if="barrierinfo">
    <div class="mpkRating__detailed__removingExports__header">
      <span class="mpkRating__detailed__removingExports__title" v-if="barrierinfo?.title">{{ barrierinfo.title }}</span>
      <CustomLink :to="{ name: 'RestrictionMeasuresPage', query: { countryId: $route.query.countryId } }">
        Подробнее
      </CustomLink>
    </div>

    <CategoryWeightAndValue :weight="categoryWeight" :value="categoryValue" />
    <div class="mpkRating__detailed__removingExports__block">
      <div class="mpkRating__detailed__removingExports__block__infoBlock">
        <span class="mpkRating__detailed__removingExports__block__infoBlock__title">
          {{ infoTitle }}
        </span>
        <span class="mpkRating__detailed__removingExports__block__infoBlock__description">
          {{ infoDescription }}
        </span>
      </div>
      <div class="mpkRating__detailed__removingExports__block__valueBlock" v-if="formatValue">
        <span class="mpkRating__detailed__removingExports__block__valueBlock__data">
          {{ formatValue }}
        </span>
        <span class="mpkRating__detailed__removingExports__block__valueBlock__text">млн $</span>
      </div>
    </div>

    <BlockBarrierQuestions class="mpkRating__detailed__questions" />
  </div>
</template>

<script>
  import CustomLink from '@/common/components/CustomLink.vue';
  import text from '@/common/mixins/text';
  import wordDeclension from '@/common/mixins/wordDeclension';

  import BlockBarrierQuestions from './BlockBarrierQuestions.vue';
  import CategoryWeightAndValue from './CategoryWeightAndValue.vue';

  export default {
    mixins: [text, wordDeclension],

    components: {
      CustomLink,
      BlockBarrierQuestions,
      CategoryWeightAndValue,
    },

    props: {
      barrierinfo: {
        type: Object,
        default: () => {},
      },
      categoryWeight: {
        type: Number,
        required: true,
      },
      categoryValue: {
        type: Number,
        required: true,
      },
    },

    computed: {
      formatValue() {
        if (this.barrierinfo?.exportValue) {
          return this.formatNumber(this.barrierinfo.exportValue / 1000000, 1);
        } else {
          return '';
        }
      },

      infoTitle() {
        return `Объем российского экспорта в ${this.barrierinfo?.country?.accusativeCase || ''} по итогам ${
          this.barrierinfo?.year || ''
        } года`;
      },

      infoDescription() {
        const barierNumber = this.barrierinfo.identifiedBarrierCount;
        const barier =
          this.barrierinfo && this.barrierinfo.identifiedBarrierCount
            ? `: ${barierNumber} ${this.wordDeclension(barierNumber, ['барьер', 'барьера', 'барьеров'])}`
            : `, барьеры в торговле с ${this.barrierinfo?.country?.instrumentalCase || ''} отсутствуют`;
        return `Согласно реестру ограничительных мер${barier}`;
      },
    },
  };
</script>

<style scoped lang="scss">
  .mpkRating__detailed__removingExports {
    width: 100%;
    margin: 0 0 40px 0;

    @media (max-width: 650px) {
      margin: 0 0 25px 0;
    }

    &__title {
      color: $base;
      font: $fira-24-M;
      display: block;
    }

    &__header {
      margin-bottom: 24px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      row-gap: 12px;
      column-gap: 24px;

      @media (max-width: $laptop) {
        margin-bottom: 16px;
      }
    }

    &__block {
      border-radius: 4px;
      background: $white;
      box-shadow: 0px 0px 16px 0px rgba(55, 55, 55, 0.08);
      padding: 16px;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 20px;

      @media (max-width: 650px) {
        flex-direction: column;
        align-items: flex-start;
      }

      &__infoBlock {
        display: flex;
        flex-direction: column;
        gap: 8px;
        max-width: 716px;
        width: 100%;

        &__title {
          color: $base;
          font: $fira-20-M;
        }

        &__description {
          color: $light-grey-12;
          font: $fira-16;
        }
      }

      &__valueBlock {
        display: flex;
        align-items: center;
        gap: 8px;
        min-width: 142px;

        &__data {
          color: $blue;
          font-variant-numeric: lining-nums tabular-nums;
          font: $fira-32-B;
        }

        &__text {
          color: $blue;
          font: $fira-12-M;
        }
      }
    }
  }

  .mpkRating__detailed__questions {
    margin-top: 24px;
  }
</style>
