<template>
  <div :class="`horizontalBarCustom ${mod || ''}`" :style="`max-width:${maxWidth};`">
    <div
      :class="`horizontalBarCustom__barBlock ${selected[index] ? 'selectedFound' : ''}`"
      v-for="(item, index) in data"
      :key="index"
      ref="barBlock"
      @click="$emit('clicked', clickedData[index])"
    >
      <span class="horizontalBarCustom__barBlock__label" v-if="labels[index]" v-html="labels[index]"></span>
      <span
        class="horizontalBarCustom__barBlock__barContainer__bar"
        :style="`background-color:${backgroundColor}; height:${heightBar};min-width: ${
          item.value > 0 ? minWidthBar : '1px'
        };`"
      >
      </span>

      <span class="horizontalBarCustom__barBlock__value">{{ item.value.toFixed(3) }}</span>

      <span class="horizontalBarCustom__barBlock__weight" v-if="weight[index]">{{ weight[index] }}</span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HorizontalBarCustom',
    props: {
      labels: {
        // Наименования баров
        default: () => [],
        type: Array,
      },
      chartData: {
        // Данные диаграммы
        default: () => [],
        type: Array,
      },
      clickedData: {
        // Даннаые передаваемые при клике
        default: () => [],
        type: Array,
      },
      selected: {
        // Массив булевых значений для выделения строки
        default: () => [],
        type: Array,
      },
      weight: {
        // вес или значения после бара справа
        default: () => [],
        type: Array,
      },
      backgroundColor: {
        // Цвет бара
        default: '#D1D5DB',
        type: String,
      },
      maxWidth: {
        // Ширина холста
        default: 'auto',
        type: String,
      },
      heightBar: {
        // Высота бара
        default: '16px',
        type: String,
      },
      minWidthBar: {
        // Минимальный предел бара
        default: '10px',
        type: String,
      },

      scalePitch: {
        // Увеличить шаг
        default: 1,
        type: Number,
      },

      mod: {
        default: '',
        type: String,
        validator(value) {
          return ['detailed', ''].includes(value);
        },
      },
    },

    computed: {
      data() {
        let data = [];

        if (this.chartData) {
          const max = Math.max(...this.chartData);
          data = this.chartData.map((item) => {
            const number = Number(item);

            return {
              // рассчитывается процентная разница
              difference: number > 0 ? Math.abs(number / max - 1) : 1,
              value: number,
            };
          });
        }

        return data;
      },
    },

    updated() {
      this.$nextTick(function () {
        this.resizeChart();
      });
    },

    mounted() {
      this.resizeChart();
      window.addEventListener('resize', this.resizeChart);
    },

    unmounted() {
      window.removeEventListener('resize', this.resizeChart);
    },

    methods: {
      resizeChart() {
        if (window.innerWidth > 450) {
          if (this.data) {
            const barBlock = this.$refs.barBlock;
            this.data.forEach((item, index) => {
              const block = barBlock[index];
              if (block) {
                const label = block.querySelector('.horizontalBarCustom__barBlock__label');
                const dataLabel = block.querySelector('.horizontalBarCustom__barBlock__value');
                const bar = block.querySelector('.horizontalBarCustom__barBlock__barContainer__bar');
                const weightLabel = block.querySelector('.horizontalBarCustom__barBlock__weight');

                const widthBar =
                  block.clientWidth -
                  ((label?.clientWidth || 0) + (dataLabel?.clientWidth || 0) + (weightLabel?.clientWidth || 0));

                const calc = (widthBar - widthBar * item.difference) * this.scalePitch;

                const minWidth = Number(calc > 0 ? this.minWidthBar.replace(/[^0-9]/g, '') : 0);

                bar.style.maxWidth = `${
                  calc < widthBar - widthBar * 0.9
                    ? calc * this.scalePitch + minWidth
                    : item.difference == 0
                      ? calc + minWidth
                      : calc / this.scalePitch + minWidth
                }px`;
                bar.style.width = '100%';
              }
            });
          }
        }
      },
    },
  };
</script>

<style lang="scss">
  .horizontalBarCustom {
    display: flex;
    flex-direction: column;

    &__barBlock {
      display: flex;
      align-items: center;
      max-width: 100%;

      &:not(:last-child) {
        margin: 0 0 8px 0;
      }

      &__label {
        color: $base;
        font: $fira-16;
        padding: 0 16px 0 0;
        max-width: 200px;
        width: 100%;
        overflow: hidden;
        display: flex;

        .count {
          color: $base;
          font: $fira-16;
          font-variant-numeric: lining-nums tabular-nums;
          padding: 0 16px 0 0;
          max-width: 32px;
          width: 100%;

          &.blue {
            color: #0078c8 !important;
          }

          &.red {
            color: #f66 !important;
          }

          &.yellow {
            color: #f4b700 !important;
          }

          &.green {
            color: #28a745 !important;
          }

          @media (max-width: 500px) {
            padding: 0 10px 0 0;
          }

          @media (max-width: 450px) {
            padding: 0 16px 0 0;
          }
        }

        .value {
          color: $base;
          font: $fira-16-M;
          font-variant-numeric: lining-nums tabular-nums;
          padding: 0 16px 0 0;
          max-width: 32px;
          width: 100%;

          &.blue {
            color: #0078c8 !important;
          }

          &.red {
            color: #f66 !important;
          }

          &.yellow {
            color: #f4b700 !important;
          }

          &.green {
            color: #28a745 !important;
          }

          @media (max-width: 830px) {
            display: none;
          }
        }

        .text {
          flex-basis: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          flex-grow: 1;
          flex-shrink: 1;
        }

        @media (max-width: 500px) {
          min-width: 180px;
          max-width: 180px;
          padding: 0 10px 0 0;
        }

        @media (max-width: 450px) {
          max-width: 100%;
          padding: 0 16px 0 0;
        }
      }

      &__barContainer {
        display: flex;
        width: 100%;
      }

      &__barContainer {
        &__bar {
          width: 0%;
          max-width: 0px;
          transition: all ease 0.3s;

          @media (max-width: 450px) {
            display: none;
          }
        }
      }

      &__value {
        color: $base;
        font-variant-numeric: lining-nums tabular-nums;
        font: $fira-16;
        max-width: 50px;
        padding: 0 0 0 16px;
        width: 100%;

        @media (max-width: 500px) {
          padding: 0 0 0 10px;
          min-width: 50px;
        }

        @media (max-width: 450px) {
          padding: 0 0 0 16px;
        }
      }

      &__weight {
        margin: 0 0 0 auto;
        color: $green;
        font: $fira-16-M;
        padding: 0 0 0 12px;
        max-width: 42px;
        width: 100%;
      }

      &.selectedFound {
        .horizontalBarCustom__barBlock__label {
          font: $fira-16-M;

          .text {
            font: $fira-16-M;
          }

          .count {
            font: $fira-16-M;
          }
        }

        .horizontalBarCustom__barBlock__barContainer__bar {
          background-color: $blue !important;
        }

        .horizontalBarCustom__barBlock__value {
          font: $fira-16-M;
        }
      }
    }

    &.detailed {
      .horizontalBarCustom__barBlock {
        &__barContainer {
          &__bar {
            @media (max-width: 730px) {
              display: none;
            }
          }
        }

        &__label {
          max-width: 320px;
          padding: 0 8px 0 0;

          @media (max-width: 830px) {
            max-width: 280px;
          }

          @media (max-width: 730px) {
            max-width: 100%;
            padding: 0 16px 0 0;
          }
        }
      }
    }
  }
</style>
