<template>
  <div>
    <section class="block-barrier-questions">
      <header class="block-barrier-questions__header">
        <h3 class="block-barrier-questions__title">
          Рассмотренные в&nbsp;рамках МПК иные проблемные вопросы, связанные с&nbsp;доступом российского экспорта
        </h3>
        <form class="block-barrier-questions__filter">
          <span>Год</span>
          <MultiSelect
            v-model="selectedYear"
            :options="displayedYearList"
            valueProp="id"
            label="text"
            :canClear="false"
            noOptionsText="Список пуст"
          />
        </form>
      </header>

      <ButtonComponent
        class="block-barrier-questions__add-button"
        @click="startCreatingNewQuestion"
        mod="gradient-bg"
        v-if="canEdit"
      >
        Добавить вопрос
      </ButtonComponent>

      <ul class="block-barrier-questions__data" v-if="displayedQuestions?.length > 0">
        <li class="questions-table" v-for="item in displayedQuestions" :key="item.id">
          <div class="questions-table__question">
            <p class="questions-table__text">
              {{ item.question }}
            </p>
            <div class="questions-table__tooltip">
              <CommentTooltip v-if="item.comment" :comment="item.comment" />
            </div>
          </div>
          <div class="questions-table__flex-status">
            <p
              class="questions-table__barrier-status barrier-status"
              :class="{ 'barrier-status__resolved': item.status.id === 1 }"
            >
              {{ item.status.name }}
            </p>
            <Toolbar v-if="canEdit" @edit="startEditingQuestion(item)" @delete="deleteQuestion(item.id)" />
          </div>
        </li>

        <ButtonComponent mod="more" @click="showMore = true" v-if="needShowMore">
          Показать все
          <IconComponent class="icon--full-svg" name="plus-mini" />
        </ButtonComponent>
      </ul>
      <p v-else>Данных не найдено</p>
    </section>

    <ModalComponent
      v-if="editableQuestion"
      v-model="isModalOpen"
      :title="modalTitle"
      headType="redesigned"
      bodyType="redesigned"
      @close="closeModal"
    >
      <form class="form-grid" @submit.prevent="saveQuestion" :novalidate="true">
        <div class="form-grid__item">
          <Field required>
            <template v-slot:label>Рассмотренный вопрос в рамках МПК</template>
            <ComponentInput
              v-model="editableQuestion.question"
              required
              placeholder="Введите текст"
              type="textarea"
              mod="rectangular"
              ref="questionInputRef"
            />
          </Field>
        </div>

        <div class="form-grid__item form-grid__item--half">
          <Field required class="block-barrier-questions__year-select">
            <template v-slot:label>Год рассмотрения</template>
            <DateYearComponent
              v-model="editableQuestion.year"
              type="date"
              required
              :lowerLimit="yearRange.min"
              :upperLimit="yearRange.max"
              placeholderForDate=""
              modClass="redesigned"
              :clearable="false"
              dateFormatProps="y"
              minimumViewDate="year"
              placeholder=""
              ref="yearInputRef"
            />
          </Field>
        </div>

        <div class="form-grid__item form-grid__item--half">
          <Field required>
            <template v-slot:label>Статус</template>
            <SelectSingleComponentCheckbox
              v-model="editableQuestion.statusId"
              placeholder="Выберите"
              trackBy="text"
              valueProp="id"
              labelOptions="text"
              :options="statuses"
              required
              :canClear="false"
            />
          </Field>
        </div>

        <div class="form-grid__item">
          <Field>
            <template v-slot:label>Комментарий</template>
            <ComponentInput
              v-model="editableQuestion.comment"
              placeholder="Соответствующая информация приведена в п.1.5 статьи 4 Рамочного соглашения о сотрудничестве между Правительством Российской Федерации и Правительством Республики Куба о поощрении участия инвесторов Российской Федерации в российско - кубинских проектах на территории Республики Куба"
              type="textarea"
              mod="rectangular"
            />
          </Field>
        </div>

        <div class="form-grid__item form-grid__footer">
          <ButtonComponent mod="gradient-bg"> Сохранить </ButtonComponent>
          <ButtonComponent @click="closeModal"> Отменить </ButtonComponent>
        </div>
      </form>
    </ModalComponent>

    <ModalInfo
      v-if="isOpenModalInfo"
      v-model="isOpenModalInfo"
      :titleModal="titleModal"
      :textModal="textModal"
      :textModalPreWrap="textModalPreWrap"
      :modalIconName="modalIconName"
      @closeModal="closeModalInfo"
    />
  </div>
</template>

<script>
  import MultiSelect from '@vueform/multiselect';
  import { useVuelidate } from '@vuelidate/core';
  import { required } from '@vuelidate/validators';

  import ComponentInput from '@/common/components/ComponentInput.vue';
  import DateYearComponent from '@/common/components/dateYearComponent.vue';
  import Field from '@/common/components/Field.vue';
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import ModalInfo from '@/common/components/redesigned/ModalInfo.vue';
  import SelectSingleComponentCheckbox from '@/common/components/redesigned/SelectRedesigned/SelectSingleComponentCheckbox.vue';
  import Toolbar from '@/common/components/Toolbar.vue';
  import Constants from '@/common/constants';
  import modalInfo from '@/common/mixins/modalInfo';
  import security from '@/common/mixins/security';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import ModalComponent from '@/components/modals/ModalComponent.vue';

  import NsiAPI from '@/modules/nsi/api/nsi.js';

  import QuestionAPI from '../api/questions.js';
  import CommentTooltip from './CommentTooltip.vue';

  const modalModes = {
    create: 'create',
    update: 'update',
  };

  export default {
    name: 'BlockBarrierQuestions',

    mixins: [security, modalInfo],

    components: {
      MultiSelect,
      SelectSingleComponentCheckbox,
      ButtonComponent,
      Toolbar,
      ModalComponent,
      ModalInfo,
      ComponentInput,
      DateYearComponent,
      Field,
      IconComponent,
      CommentTooltip,
    },

    data() {
      return {
        canEdit: false,
        selectedYear: null,
        yearList: null,
        questions: null,
        statuses: null,
        editableQuestion: null,
        showMore: false,
        displayedCount: 5,
      };
    },

    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        editableQuestion: {
          statusId: { required },
          year: { required },
          question: { required },
        },
      };
    },

    computed: {
      countryId() {
        return this.$route.query?.countryId ?? null;
      },

      isModalOpen: {
        get() {
          return !!this.editableQuestion;
        },
        set(value) {
          if (value === false) {
            this.editableQuestion = null;
          }
        },
      },

      modalTitle() {
        const titles = {
          [modalModes.create]: 'Добавление вопроса',
          [modalModes.update]: 'Редактирование вопроса',
        };
        return titles[this.editableQuestion?.mode] ?? null;
      },

      // диапазон дат с разницей в 5 лет
      yearRange() {
        const currentDate = new Date();
        return {
          min: new Date(currentDate.getFullYear() - 5, 0, 1),
          max: currentDate,
        };
      },

      displayedYearList() {
        return this.yearList ? [...this.yearList].sort((a, b) => +b.id - +a.id) : [];
      },

      displayedQuestions() {
        return this.showMore ? this.questions : this.questions?.slice?.(0, this.displayedCount);
      },

      needShowMore() {
        return this.questions?.length > this.displayedCount && !this.showMore;
      },
    },

    methods: {
      async fetchStatuses() {
        this.statuses = await NsiAPI.mpkBarrierQuestionsStatuses()
          .then((response) => response.data)
          .catch(() => null);
      },

      async fetchYears() {
        if (!this.countryId) {
          return;
        }

        this.yearList = await QuestionAPI.getYearList(this.countryId)
          .then((response) => response.data)
          .catch(() => null);
      },

      async fetchQuestions() {
        if (!this.countryId && !this.selectedYear) {
          return;
        }

        this.questions = await QuestionAPI.get({
          countryId: this.countryId,
          YearFrom: this.selectedYear,
          YearTo: this.selectedYear,
          pageSize: 0,
        })
          .then((response) => response?.data?.items)
          .catch(() => null);
      },

      async deleteQuestion(id) {
        const confirmation = await Constants.alert.fire(Constants.confirmOptions.delete);
        if (!confirmation.isConfirmed) {
          return;
        }

        try {
          await QuestionAPI.delete(id);
          this.isOpenModalInfo = true;
          this.titleModal = 'Удаление вопроса';
          this.textModal = 'Вопрос успешно удалён';
          this.modalIconName = 'acceptIcon';

          await this.fetchYears();
        } catch (error) {
          this.isOpenModalInfo = true;
          this.titleModal = 'Удаление вопроса';
          this.textModal = Constants.commonError;
          this.modalIconName = 'errorIcon';
        }
      },

      async createQuestion() {
        if (!this.editableQuestion) {
          return;
        }

        if (!this.countryId) {
          return;
        }

        return await QuestionAPI.create({
          countryId: this.countryId,
          question: this.editableQuestion.question,
          year: this.editableQuestion.year,
          statusId: this.editableQuestion.statusId,
          comment: this.editableQuestion.comment,
        });
      },

      async updateQuestion() {
        if (!this.editableQuestion) {
          return;
        }

        if (!this.countryId) {
          return;
        }

        return await QuestionAPI.update(this.editableQuestion.id, {
          id: this.editableQuestion.id,
          countryId: this.countryId,
          question: this.editableQuestion.question,
          year: this.editableQuestion.year,
          statusId: this.editableQuestion.statusId,
          comment: this.editableQuestion.comment,
        });
      },

      closeModal() {
        this.editableQuestion = null;
      },

      startCreatingNewQuestion() {
        this.editableQuestion = {
          mode: modalModes.create,
          question: '',
          year: new Date().getFullYear(),
          statusId: this.statuses?.length > 0 ? this.statuses[this.statuses.length - 1].id : null,
        };
      },

      startEditingQuestion(questionData) {
        this.editableQuestion = {
          mode: modalModes.update,
          id: questionData.id,
          question: questionData.question,
          year: questionData.year,
          statusId: questionData.statusId,
          comment: questionData.comment,
        };
      },

      async saveQuestion() {
        if (!this.editableQuestion) {
          return;
        }

        this.v$.$touch();

        if (this.v$.editableQuestion.$invalid) {
          this.titleModal = 'Ошибка';
          this.textModal = 'Проверьте правильность заполнения полей.';
          this.modalIconName = 'errorIcon';
          this.isOpenModalInfo = true;
          return;
        }

        try {
          if (this.editableQuestion.mode === modalModes.create) {
            await this.createQuestion();
          } else if (this.editableQuestion.mode === modalModes.update) {
            await this.updateQuestion();
          }

          this.closeModal();

          this.isOpenModalInfo = true;
          this.titleModal = 'Сохранение';
          this.textModal = 'Данные успешно применены';
          this.modalIconName = 'acceptIcon';

          await this.fetchYears();
        } catch (error) {
          this.isOpenModalInfo = true;
          this.titleModal = this.modalTitle;
          this.textModal = Constants.commonError;
          this.modalIconName = 'errorIcon';
        }
      },
    },

    watch: {
      displayedYearList(list) {
        if (!(list?.length > 0)) {
          return;
        }

        const isListHasSelectedYear = list.some((item) => item.id == this.selectedYear);
        if (isListHasSelectedYear) {
          this.fetchQuestions();
          return;
        }

        this.selectedYear = list[0].id;
      },

      selectedYear() {
        this.fetchQuestions();
      },

      countryId() {
        this.fetchYears();
      },
    },

    async created() {
      this.canEdit = await this.checkPermission(Constants.Permissions.MpkBarrierQuestionsEdit)
        .then((response) => response?.data)
        .catch(() => false);

      await Promise.all([this.fetchStatuses(), this.fetchYears()]);
    },
  };
</script>

<style lang="scss" scoped>
  .block-barrier-questions {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-row-gap: 24px;
    padding: 16px 24px 24px;
    border: 1px solid $grey;
    background-color: $light-grey;
    border-radius: 4px;
  }

  .block-barrier-questions__header {
    display: flex;
    align-items: start;
    gap: 32px;

    @media (max-width: $mobile) {
      flex-direction: column;
      gap: 16px;
    }
  }

  .block-barrier-questions__title {
    flex: 1 1 auto;
    font: $fira-20-M;
  }

  .block-barrier-questions__filter {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    gap: 8px;

    .multiselect {
      flex: 0 0 auto;
      width: 135px;
    }
  }

  .block-barrier-questions__add-button {
    justify-self: start;
  }

  .block-barrier-questions__year-select {
    :deep(.v3dp__popout) {
      top: auto;
      bottom: 100%;
      left: 0;
      right: 0;
      width: auto;
    }
  }

  .block-barrier-questions__data {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .questions-table {
    display: flex;
    justify-content: space-between;
    align-items: start;

    &__question {
      display: flex;
      align-items: start;
      gap: 16px;
      max-width: 625px;
      width: 100%;
    }

    &__flex-status {
      display: flex;
      gap: 32px;
    }

    &__barrier-status {
      width: 102px;
    }

    &__text {
      flex-grow: 1;
    }

    &__tooltip {
      display: flex;
      align-items: center;
      min-width: 24px;
    }

    &__status {
      display: flex;
      gap: 32px;
    }

    @media (max-width: $mobile) {
      flex-direction: column;
      gap: 8px;

      &__flex-status {
        gap: 16px;
      }
    }
  }

  .barrier-status {
    --_status-color: #{$red-2};

    display: flex;
    align-items: center;
    gap: 4px;
    font: unset;
    white-space: nowrap;

    &::before {
      content: '';
      margin-inline: 6px;
      flex: 0 0 auto;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: var(--_status-color);
    }
  }

  .barrier-status__resolved {
    --_status-color: #{$green-2};
  }

  .form-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-row-gap: 32px;
    grid-column-gap: 16px;
  }

  .form-grid__item {
    grid-column: span 2;
  }

  .form-grid__item--half {
    grid-column: span 1;
  }

  .form-grid__footer {
    display: flex;
    align-items: center;
    gap: 16px;
  }
</style>
