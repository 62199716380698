<template>
  <div class="collapse-component" :style="collapseStyle">
    <div class="collapse-component__header-wrapper" @click="onClick()">
      <div class="collapse-component__header">
        <slot name="header">
          <div class="header">
            <h4 class="header__item-header">
              {{ name }}
            </h4>
            <span class="header__item-status">{{ resolveCrosscuttingSubtaskCount(content) }}</span>
          </div>
        </slot>
      </div>
      <slot name="icon">
        <div class="collapse-component__icon">
          <img :class="{ 'collapse-component__icon--open': isOpen }" src="@/assets/images/svg/arrowCurrentColor.svg" />
        </div>
      </slot>
    </div>

    <div v-if="isOpen">
      <slot name="body">
        <div class="body">
          <ButtonComponent v-if="hasMoreButton" class="body__button" mod="only-blue-text" @click="toggleHiddenText">
            {{ isHiddenText ? 'Показать полностью' : 'Скрыть' }}
          </ButtonComponent>

          <div class="body__country">
            <p class="body__title">Целевые страны:</p>
            <p class="body__text" ref="countryContainer" :class="{ body__text_hidden: isHiddenText }">
              {{ concatFieldsFromArr(countries, 'name') }}
            </p>
          </div>
          <div class="body__level">
            <p class="body__title">Уровень достижения:</p>
            <p class="body__digit" :class="{ body__text_hidden: isHiddenText }">{{ achievementLevel ?? '0' }}</p>
          </div>
          <div class="body__subtask">
            <div v-for="task in content" :key="task.id" class="body__wrapper">
              <p class="body__header" ref="nameContainer">
                {{ task.name }}
              </p>
              <div class="body__description">
                <span class="circle" :class="resolveCrosscutingSubtaskStatus(task.status)"></span>
                <p class="w-100 body__text" :class="{ body__text_hidden: isHiddenText }" ref="commentContainer">
                  {{ task.comment }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import Constants from '@/common/constants';

  export default {
    name: 'Collapse',
    components: {
      ButtonComponent,
    },
    props: {
      styleOpen: {
        type: Object,
        default: () => {},
      },
      name: {
        type: String,
        default: '',
      },
      content: {
        type: Array,
        default: () => [],
      },
      countries: {
        type: Array,
        default: () => [],
      },
      achievementLevel: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        isOpen: false,
        isHiddenText: false,
        hasMoreButton: false,
      };
    },
    computed: {
      collapseStyle() {
        return this.isOpen ? this.styleOpen : {};
      },
    },
    methods: {
      onClick() {
        this.isOpen = !this.isOpen;
        if (this.isOpen) {
          this.isTextExceedingThreeLines();
        }
      },
      // проверяем, превышает ли текст внутри collapse в каждом контейнере больше 3 строк
      isTextExceedingThreeLines() {
        this.$nextTick(() => {
          const countryContainer = this.$refs.countryContainer;
          const nameContainers = this.$refs.nameContainer;
          const commentContainers = this.$refs.commentContainer;

          const countryStyle = window.getComputedStyle(countryContainer);
          const nameStyles = nameContainers.map((item) => window.getComputedStyle(item));
          const commentStyles = commentContainers.map((item) => window.getComputedStyle(item));

          const lineHeightForCounty = parseFloat(countryStyle.lineHeight);
          const lineHeightForName = nameStyles.map((item) => parseFloat(item.lineHeight));
          const lineHeightForComment = commentStyles.map((item) => parseFloat(item.lineHeight));

          const countryContainerHeight = countryContainer.scrollHeight;
          const nameContainerHeights = nameContainers.map((item) => item.scrollHeight);
          const commentContainerHeights = commentContainers.map((item) => item.scrollHeight);

          const isHasButtonForCountry = countryContainerHeight > lineHeightForCounty * 3;
          const isHasButtonForName = nameContainerHeights.some((item, index) => item > lineHeightForName[index] * 3);
          const isHasButtonForComment = commentContainerHeights.some(
            (item, index) => item > lineHeightForComment[index] * 3,
          );
          const isHadButton = isHasButtonForCountry || isHasButtonForName || isHasButtonForComment;
          if (isHadButton) {
            this.isHiddenText = true;
            this.hasMoreButton = true;
          }
        });
      },
      toggleHiddenText() {
        this.isHiddenText = !this.isHiddenText;
      },
      resolveCrosscuttingSubtaskCount(subtasks) {
        const completed = subtasks.filter(({ status }) => status === Constants.CrosscuttingSubtaskStatus.COMPLETED);
        return `${completed.length}/${subtasks.length} подзадачи`;
      },
      concatFieldsFromArr(arr, field) {
        const fields = arr.map((elem) => elem[field]);
        return fields.join(', ');
      },
      resolveCrosscutingSubtaskStatus(statusId) {
        if (statusId === Constants.CrosscuttingSubtaskStatus.NOT_COMPLETED) {
          return 'bg-red';
        }
        if (statusId === Constants.CrosscuttingSubtaskStatus.WORK) {
          return 'bg-yellow';
        }
        if (statusId === Constants.CrosscuttingSubtaskStatus.COMPLETED) {
          return 'bg-green';
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .collapse-component {
    background: $light-grey;
    border: 1px solid $grey;
    border-radius: 3px;
    padding: 16px 24px;

    &__header-wrapper {
      display: flex;
      position: relative;
    }

    &__header {
      width: 98%;
    }

    &__icon {
      position: absolute;
      top: 22px;
      right: 10px;
      cursor: pointer;
      min-width: 15px;

      &--open {
        transform: rotate(180deg);
      }
    }
  }
  .header {
    display: flex;
    align-items: start;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 16px;

    &__item-header {
      font: $fira-20-M;
      color: $base;
      max-width: 85%;
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -moz-box;
      -moz-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      line-clamp: 3;
      box-orient: vertical;
    }

    &__item-status {
      font: $fira-16-M;
      background-color: $light-blue-2;
      border-radius: 3px;
      padding: 5px 10px;
      color: $blue;
      margin-right: 10px;
    }
  }
  .body {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__wrapper {
      display: flex;
      width: 100%;
      gap: 40px;
    }

    &__country {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &__title {
      font: $fira-16-M;
    }

    &__text_hidden {
      overflow: hidden;
      word-wrap: break-word;
      text-overflow: ellipsis;
      display: -moz-box;
      -moz-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      line-clamp: 3;
      box-orient: vertical;
    }

    &__level {
      display: flex;
      gap: 8px;
    }

    &__digit {
      background-image: linear-gradient(90deg, $blue 0%, $green 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font: $fira-16-M;
    }

    &__subtask {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    &__header {
      width: 49%;
      color: $gray-silver-dark;
      font: $fira-16;
    }
    &__description {
      display: flex;
      width: 49%;
      font: $fira-16;
    }
  }
  .circle {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-top: 8px;
    margin-right: 10px;
  }
  .bg-green {
    background-color: $approval-text;
  }
  .bg-yellow {
    background-color: $yellow-2;
  }
  .bg-red {
    background-color: $red-2;
  }

  @media (max-width: $laptop) {
    .body {
      &__wrapper {
        gap: 8px;
      }
    }
  }

  @media (max-width: $mobile) {
    .collapse-component {
      padding: 16px;
    }
    .header__item-header {
      font: $fira-16-M;
    }
    .header__item-status {
      font: $fira-14-M;
    }

    .body__header,
    .body__description {
      font: $fira-14;
    }
    .body__header,
    .body__description {
      width: 100%;
    }

    .body {
      gap: 8px;

      &__wrapper {
        flex-direction: column;
        gap: 16px;
      }

      &__button {
        padding: 8px 0;
      }

      &__title,
      &__digit {
        font: $fira-14-M;
      }

      &__text {
        font: $fira-14;
      }

      &__level {
        margin-bottom: 8px;
      }
    }
  }
</style>
