<template>
  <div class="content mpkRating">
    <div class="container-page big">
      <div class="mpkRating__titleBlock">
        <h2 class="title title--h2 mpkRating__titleBlock__title">Рейтинг эффективности МПК</h2>
        <button class="mpkRating__titleBlock__button" @click="exportToPDF" v-if="activeDetailed">
          Скачать страницу
          <svg
            class="mpkRating__titleBlock__button__icon"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M20.75 14V13.25H19.25V14H20.75ZM20 18H20.75H20ZM4 18H4.75H4ZM4.75 14V13.25H3.25V14H4.75ZM7.53033 9.46967L7 8.93934L5.93934 10L6.46967 10.5303L7.53033 9.46967ZM12 15L11.4697 15.5303L12 16.0607L12.5303 15.5303L12 15ZM17.5303 10.5303L18.0607 10L17 8.93934L16.4697 9.46967L17.5303 10.5303ZM12.75 3V2.25H11.25V3H12.75ZM19.25 14V18H20.75V14H19.25ZM19.25 18C19.25 18.3582 19.1227 18.6865 18.9187 18.9159L20.0399 19.9125C20.5027 19.3918 20.75 18.7027 20.75 18H19.25ZM18.9187 18.9159C18.7173 19.1425 18.4644 19.25 18.2222 19.25V20.75C18.923 20.75 19.5745 20.436 20.0399 19.9125L18.9187 18.9159ZM18.2222 19.25H5.77778V20.75H18.2222V19.25ZM5.77778 19.25C5.53561 19.25 5.28268 19.1425 5.08126 18.9159L3.96014 19.9125C4.42551 20.436 5.07695 20.75 5.77778 20.75V19.25ZM5.08126 18.9159C4.87728 18.6865 4.75 18.3582 4.75 18H3.25C3.25 18.7027 3.49733 19.3918 3.96014 19.9125L5.08126 18.9159ZM4.75 18V14H3.25V18H4.75ZM6.46967 10.5303L11.4697 15.5303L12.5303 14.4697L7.53033 9.46967L6.46967 10.5303ZM12.5303 15.5303L17.5303 10.5303L16.4697 9.46967L11.4697 14.4697L12.5303 15.5303ZM12.75 15V3H11.25V15H12.75Z"
              fill="white"
            />
          </svg>
        </button>
      </div>

      <div class="mpkRating__countrySelectorBlock">
        <ComponentInput
          @select2OnSelect="changeCountry"
          :select2Options="countries"
          :select2Settings="select2Settings"
          v-model="countryId"
          label="Страна"
          placeholder="Выберите страну"
          type="select2"
        />
      </div>
      <General :selectTab="selectTab" v-if="!activeDetailed" />
      <Detailed @selectTab="changeTabGeneral" v-if="selectedCountry" :country="selectedCountry" />
    </div>
  </div>
</template>

<script>
  import html2pdf from 'html2pdf.js';

  import ComponentInput from '@/common/components/ComponentInput';
  import Constants from '@/common/constants';

  import API from '../api';
  import Detailed from '../components/Detailed';
  import General from '../components/General';

  const ALL_COUNTRIES_OPTION_ID = '-1';

  export default {
    name: 'EfficiencyRating',
    components: {
      ComponentInput,
      General,
      Detailed,
    },

    data() {
      return {
        countryId: this.$route?.query?.countryId || ALL_COUNTRIES_OPTION_ID,
        countries: [],
        selectTab: {},
      };
    },

    created() {
      this.loadData();
    },

    watch: {
      '$route.query.countryId'() {
        this.countryId = this.$route?.query?.countryId || ALL_COUNTRIES_OPTION_ID;
      },
    },

    computed: {
      activeDetailed() {
        return this.countryId == ALL_COUNTRIES_OPTION_ID ? false : true;
      },

      selectedCountry() {
        if (this.countryId == ALL_COUNTRIES_OPTION_ID) {
          return null;
        }
        return this.countries.find((country) => country.id == this.countryId);
      },

      select2Settings() {
        const data = Constants.select2SettingsNoClear;
        data.theme = 'default greenMagnifierTheme';
        return data;
      },
    },

    methods: {
      exportToPDF() {
        const exportContainer = document.querySelector('.mpkRating__detailed');
        if (exportContainer) {
          exportContainer.classList.add('mpkRating_exportPdf');
          const opt = {
            margin: 2,
            filename: 'reting-effektivnosti-mpk-detailed.pdf',
            image: { type: 'png', quality: 1 },
            html2canvas: {
              windowWidth: 1920,
              dpi: 192,
              scale: 1,
              letterRendering: true,
              useCORS: true,
            },
            jsPDF: {
              unit: 'mm',
              format: 'a3',
              orientation: 'portrait',
              align: 'center',
              baseline: 'middle',
            },
            pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
          };

          html2pdf()
            .set(opt)
            .from(exportContainer)
            .save()
            .then(() => {
              exportContainer.classList.remove('mpkRating_exportPdf');
            });
        }
      },

      changeTabGeneral(e) {
        this.selectTab = e;
      },

      changeCountry() {
        if (this.activeDetailed) {
          this.$router.push({
            name: 'efficiencyrating',
            query: { countryId: this.countryId },
          });
        } else {
          this.$router.push({ name: 'efficiencyrating' });
        }
        this.selectTab = {};
      },

      loadData() {
        API.getCountryList().then((response) => {
          this.countries.push({
            id: ALL_COUNTRIES_OPTION_ID,
            selected: true,
            text: 'Все',
          });
          this.countries.push(...response.data);
        });
      },
    },
  };
</script>

<style lang="scss">
  .mpkRating {
    &__titleBlock {
      display: flex;
      max-width: 956px;
      margin: 0 0 20px 0;

      @media (max-width: 600px) {
        gap: 20px;
        flex-direction: column;
      }

      &__title {
        color: $base;
        font: $fira-24-B;
        text-transform: uppercase;
        align-self: center;
      }

      &__button {
        margin: 0 0 0 auto;
        padding: 8px 24px;
        border-radius: 4px;
        border: none;
        outline: none;
        background-color: #01b0df;
        color: $white;
        font: $fira-14-B;

        @media (max-width: 600px) {
          margin: 0;
          width: 100%;
        }
      }
    }

    &__countrySelectorBlock {
      max-width: 416px;
      margin: 0 0 20px 0;

      .form-label {
        color: $base;
        font: $fira-16;
      }

      .select2 {
        &.select2-container {
          .select2-selection {
            padding: 8px 8px 8px 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .select2-selection__arrow {
              position: relative;
              right: auto;
              top: auto !important;
              transform: none !important;
            }
          }

          &:hover {
            border: 1px solid rgba(9, 174, 116, 0.4);
          }

          &.select2-container--open {
            border: 1px solid rgba(9, 174, 116, 0.4);
            border-radius: 4px 4px 0 0 !important;
          }
        }
      }
    }
  }

  .select2-container.select2-container--default.greenMagnifierTheme .select2-search--dropdown {
    padding: 16px 12px;
    border-right: 1px solid $light-grey-5;
    border-bottom: 1px solid $light-grey-5;
    border-left: 1px solid $light-grey-5;
    background: $white;
    position: relative;
    display: flex;
    align-items: center;

    &::after {
      content: '';
      position: absolute;
      width: 25px;
      height: 25px;
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M20 20L16.1333 16.1333M18.2222 11.1111C18.2222 15.0385 15.0385 18.2222 11.1111 18.2222C7.18375 18.2222 4 15.0385 4 11.1111C4 7.18375 7.18375 4 11.1111 4C15.0385 4 18.2222 7.18375 18.2222 11.1111Z' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
      mask-size: 25px;
      mask-repeat: no-repeat;
      opacity: 0.3;
      background-color: $black-st;
      right: 22px;
      transition: 0.3s;
    }

    &:has(.select2-search__field:hover) {
      &::after {
        opacity: 1;
      }
    }

    &:has(.select2-search__field:focus) {
      &::after {
        opacity: 1;
      }
    }

    & .select2-search__field {
      padding: 8px 12px;
      border-radius: 4px;
      border: 1px solid $light-grey-5;
      background: $white;
      font: $fira-16;

      color: $base;

      &:hover {
        border: 1px solid rgba(9, 174, 116, 0.4);
      }

      &:focus {
        border: 1px solid $green-1;
      }
    }
  }

  .select2-container--default.greenMagnifierTheme .select2-dropdown {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 4px 4px;
  }

  .select2-container.select2-container--default.greenMagnifierTheme {
    .select2-results .select2-results__options {
      max-height: 255px;
    }

    .select2-results__option {
      padding: 8px 16px;
      font: $fira-16;
      color: $base;
      border: 1px solid $light-grey-5;
      background-color: $white;

      &[aria-selected] {
        color: $base;
        background-color: $white;

        &:hover {
          background: #f3f4f6;
          color: $base;
        }
      }

      &[aria-selected='true'] {
        border: 0px solid $light-grey-5;
        background: $green-1;
        color: $white;

        &:hover {
          background: $green-1;
          color: $white;
        }
      }
    }
  }
</style>
