<template>
  <div class="mpkRating__detailed">
    <div class="mpkRating__detailed__header">
      <div class="mpkRating__detailed__titleBlock">
        <span class="mpkRating__detailed__titleBlock__title" v-if="country?.text">Россия — {{ country.text }}</span>
        <span class="mpkRating__detailed__titleBlock__point" v-if="place">{{ place }} место</span>
      </div>
      <div class="mpkRating__detailed__structure-links">
        <CustomLink :to="{ name: 'Commissions', query: { countryId: country.id } }"> Структура МПК </CustomLink>
        <CustomLink :to="{ name: 'business-tips', query: { countryId: country.id } }"> Деловой совет </CustomLink>
      </div>
    </div>
    <template v-if="infoAboutCommissions.length > 0">
      <div
        class="mpkRating__detailed__mpkInfoBlock"
        v-for="(item, index) in infoAboutCommissions"
        :key="`${item.countryId}-${index}`"
      >
        <span class="mpkRating__detailed__description" v-if="item?.name">{{ item.name }}</span>

        <CochairsEvents v-if="item?.mpkParticipants?.length" :data="item" />

        <div class="mpkRating__detailed__achievement" v-if="needShowAchievements(item)">
          <div class="mpkRating__detailed__achievement__titleBlock">
            <span class="mpkRating__detailed__achievement__titleBlock__title">Достижения в рамках МПК</span>
            <IconComponent
              name="edit"
              v-if="canEditAchievements"
              @click="editAchievementsSetup(getCommissionId(item))"
              :class="['mpkRating__detailed__achievement__titleBlock__editButton']"
            />
          </div>

          <div
            class="mpkRating__detailed__achievement__text"
            v-if="achievements[getCommissionId(item)]"
            v-html="achievements[getCommissionId(item)]"
          ></div>
          <p v-else>Нет данных</p>
        </div>
      </div>
    </template>

    <div class="mpkRating__detailed__ratingEfficiency" v-if="ratingEfficiency.length > 0">
      <div class="mpkRating__detailed__ratingEfficiency__titleBlock">
        <span class="mpkRating__detailed__ratingEfficiency__titleBlock__title">Рейтинг эффективности МПК</span>
        <Tooltip :triggers="['click']" class="tooltip-component--mobile-big">
          <span class="mpkRating__detailed__ratingEfficiency__titleBlock__helpButton__description">
            1 — максимальное значение компонента, достигнутое целевой страной
          </span>
          <span class="mpkRating__detailed__ratingEfficiency__titleBlock__helpButton__infographics">
            <span class="green">Зелёный</span> цвет рейтинга — 1-15 позиция,<br />
            <span class="yellow">желтый</span> — 16-30 позиция, <span class="red">красный</span> — от 31
          </span>
        </Tooltip>
      </div>
      <div class="mpkRating__detailed__ratingEfficiency__infographics">
        <div class="mpkRating__detailed__ratingEfficiency__infographics__valueBlock">
          Итоговое значение
          <span class="mpkRating__detailed__ratingEfficiency__infographics__valueBlock__value">{{ totalValue }}</span>
        </div>
        <span class="mpkRating__detailed__ratingEfficiency__infographics__weight">Вес</span>
      </div>
      <div class="mpkRating__detailed__ratingEfficiency__chartBar">
        <HorizontalBarCustom
          @clicked="selectRating"
          mod="detailed"
          :scalePitch="1"
          :chartData="chartData"
          :labels="chartLabels"
          :clickedData="ratingEfficiency"
          :weight="chartWeight"
        />
      </div>
    </div>

    <div class="mpkRating__detailed__statusTasks">
      <div class="mpkRating__detailed__statusTasks__titleBlock">
        <span class="mpkRating__detailed__statusTasks__titleBlock__title">Статус выполнения сквозных задач</span>
        <CustomLink :to="{ name: 'cross-cutting-task', params: { id: country.id } }"> Все задачи </CustomLink>
      </div>

      <CategoryWeightAndValue :weight="0.35" :value="mpkRatingForCountryDetailed.crossCuttingTasksRating?.toFixed(3)" />
      <div class="mpkRating__detailed__statusTasks__accordionBlock">
        <div
          class="mpkRating__detailed__statusTasks__accordionBlock__accordionCategory"
          v-for="(task, index) in throughTasks.tasksByThematicBlocks"
          :key="index"
        >
          <span class="mpkRating__detailed__statusTasks__accordionBlock__accordionCategory__title">
            {{ task.thematicBlock.name }}
          </span>
          <template v-for="crossTask in task.crossCuttingTasks" :key="crossTask.id">
            <Collapse
              :name="crossTask.name"
              :content="crossTask.subtasks"
              :countries="crossTask.countries"
              :achievementLevel="crossTask.achievementLevel"
            />
          </template>
        </div>
      </div>
    </div>

    <BlockBarrier
      :barrierinfo="barrierinfoData"
      :categoryWeight="0.1"
      :categoryValue="mpkRatingForCountryDetailed.tradeBarriersRemovalRating?.toFixed(3)"
    />

    <div class="mpkRating__detailed__implementationProjects">
      <div class="mpkRating__detailed__implementationProjects__titleBlock">
        <span class="mpkRating__detailed__implementationProjects__titleBlock__title">
          Реализация двусторонних проектов
        </span>
        <CustomLink
          :to="{
            name: 'Projects',
            query: {
              countryId: $route.query.countryId,
              purposeId: [3, 4, 7],
            },
          }"
        >
          Все проекты
        </CustomLink>
      </div>

      <CategoryWeightAndValue :weight="0.15" :value="mpkRatingForCountryDetailed.bilateralProjectsRating?.toFixed(3)" />

      <div class="mpkRating__detailed__implementationProjects__chart">
        <VerticalBarChart
          :labels="implementationProjects.labels"
          :datasets="implementationProjects.datasets"
          :title="implementationProjects.title"
          :stepSizeY="1"
        />
      </div>
    </div>

    <div class="mpkRating__detailed__levelDevelopment">
      <div class="mpkRating__detailed__levelDevelopment__header">
        <span class="mpkRating__detailed__levelDevelopment__title">Уровень развития торговли</span>
        <CustomLink :to="{ name: 'countryAnalytics', query: { countryId: $route.query.countryId } }">
          Подробнее
        </CustomLink>
      </div>

      <CategoryWeightAndValue :weight="0.1" :value="mpkRatingForCountryDetailed.tradeDevelopmentRating?.toFixed(3)" />

      <div class="mpkRating__detailed__levelDevelopment__chart">
        <VerticalBarChart
          tooltip="custom"
          :labels="levelDevelopment.labels"
          :datasets="levelDevelopment.datasets"
          :title="levelDevelopment.title"
        />
      </div>
    </div>
  </div>

  <ModalComponent
    @close="editAchievements.active = false"
    title="Редактирование"
    v-model="editAchievements.active"
    :themeLigth="true"
    :width="704"
    content-class="modal--new"
  >
    <div class="mpkRating__editAchievements">
      <div class="mpkRating__editAchievements__editor">
        <QuillEditor
          @update:content="editAchievementsContentUpdate"
          :toolbar="[{ list: 'bullet' }]"
          theme="snow"
          v-model:content="editAchievements.content"
          contentType="html"
        />
      </div>
      <div class="mpkRating__editAchievements__navigation">
        <ButtonComponent
          mod="gradient-bg"
          @click="editAchievementsSave()"
          class="mpkRating__editAchievements__navigation__button mpkRating__editAchievements__navigation__button__save"
          >Сохранить</ButtonComponent
        >
        <ButtonComponent
          class="mpkRating__editAchievements__navigation__button mpkRating__editAchievements__navigation__button_cancel"
          @click="editAchievements.active = false"
          mod="transparent-bg"
          >Отменить</ButtonComponent
        >
      </div>
    </div>
  </ModalComponent>
</template>

<script>
  import { isEmpty } from 'lodash';

  import Collapse from '@/common/components/Collapse.vue';
  import CustomLink from '@/common/components/CustomLink.vue';
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import Constants from '@/common/constants';
  import IconComponent from '@/common/ui/IconComponent.vue';
  import Tooltip from '@/common/ui/Tooltip.vue';

  import ModalComponent from '@/components/modals/ModalComponent.vue';

  import API from '../api';
  import BlockBarrier from './BlockBarrier.vue';
  import CategoryWeightAndValue from './CategoryWeightAndValue.vue';
  import CochairsEvents from './CochairsEvents.vue';
  import HorizontalBarCustom from './HorizontalBarCustom';
  import VerticalBarChart from './VerticalBarChart.vue';

  export default {
    name: 'Detailed',

    components: {
      CochairsEvents,
      Tooltip,
      HorizontalBarCustom,
      Collapse,
      ButtonComponent,
      VerticalBarChart,
      BlockBarrier,
      IconComponent,
      ModalComponent,
      CustomLink,
      CategoryWeightAndValue,
    },

    props: {
      country: {
        type: Object,
        default: () => {},
      },
    },
    emits: ['selectTab'],

    data() {
      return {
        place: '',

        totalValue: '',

        mpkRatingForCountryDetailed: {},

        ratingEfficiencyData: [],

        barrierinfo: {},

        levelDevelopmentData: {},

        throughTasks: {},

        implementationProjectsData: {},

        infoAboutCommissionsData: [],

        achievements: {},

        editAchievements: {
          permission: false,
          active: false,
          mpkId: 0,
          content: '',
        },

        canEditAchievements: false,
      };
    },

    watch: {
      '$route.query.countryId': {
        handler: function (id) {
          if (id) {
            this.loadData();
          }
        },
        deep: true,
        immediate: true,
      },
    },

    computed: {
      ratingEfficiency() {
        return this.ratingEfficiencyData.map((data) => {
          data.country = this.country?.text;
          return data;
        });
      },

      infoAboutCommissions() {
        return this.infoAboutCommissionsData;
      },

      chartData() {
        return this.ratingEfficiency.map((data) => data.value.toFixed(3));
      },

      chartLabels() {
        return this.ratingEfficiency.map((data) => {
          return `
        <span class ='value ${data.modRating || ''}'>
          ${data.rating}
        </span>
        <span class='text'> ${data.name}</span>`;
        });
      },

      chartWeight() {
        return this.ratingEfficiency.map((data) => data.weight);
      },

      barrierinfoData() {
        const year = new Date().getFullYear() - 1;

        return {
          title: 'Снятие барьеров в экспорте',
          identifiedBarrierCount: this.barrierinfo?.identifiedBarrierCount,
          country: {
            instrumentalCase: this.infoAboutCommissionsData[0]?.country?.instrumentalCase,
            accusativeCase: this.infoAboutCommissionsData[0]?.country?.accusativeCase,
          },
          exportValue: this.levelDevelopmentData[year]?.exportValue,
          year: year,
        };
      },

      implementationProjects() {
        const data = this.implementationProjectsData;

        // Проверяю длину названия страны и если больше или равно 12 символов, то переношу все на новые строки через каждые 2 слова
        let firstLabel =
          this.getPrepositionalCaseCountry.length >= 12
            ? this.lineBreaks(`Российские проекты в ${this.getPrepositionalCaseCountry}`, 2)
            : [`Российские проекты в ${this.getPrepositionalCaseCountry}`];

        let secondtLabel =
          this.infoAboutCommissionsData[0]?.country?.genetiveCase?.length >= 12
            ? this.lineBreaks(`Проекты ${this.infoAboutCommissionsData[0].country.genetiveCase} в России`, 2)
            : [`Проекты ${this.infoAboutCommissionsData[0]?.country?.genetiveCase || ''} в России`];

        const doneCount = !isEmpty(data)
          ? [data.russianProjectInOtherCountry.doneCount, data.otherCountryProjectsInRussia.doneCount]
          : [];
        const inProgressCount = !isEmpty(data)
          ? [data.russianProjectInOtherCountry.inProgressCount, data.otherCountryProjectsInRussia.inProgressCount]
          : [];
        const perspectiveCount = !isEmpty(data)
          ? [data.russianProjectInOtherCountry.perspectiveCount, data.otherCountryProjectsInRussia.perspectiveCount]
          : [];
        return {
          labels: [firstLabel, secondtLabel],
          datasets: [
            {
              label: 'Реализован',
              data: doneCount,
              backgroundColor: '#73EA48',
            },
            {
              label: 'В стадии реализации',
              data: inProgressCount,
              backgroundColor: '#17A1FA',
            },
            {
              label: 'Подготовка к реализации',
              data: perspectiveCount,
              backgroundColor: '#01A39D',
            },
          ],
          title: 'Количество проектов (шт)',
        };
      },

      levelDevelopment() {
        const levelDevelopmentDataKeys = Object.keys(this.levelDevelopmentData);
        const fisrs = this.levelDevelopmentData[levelDevelopmentDataKeys[0]];
        const second = this.levelDevelopmentData[levelDevelopmentDataKeys[1]];

        const firstData = fisrs
          ? [
              { x: this.toBillion(fisrs.totalValue) },
              { x: this.toBillion(fisrs.exportValue) },
              { x: this.toBillion(fisrs.importValue) },
            ]
          : [];
        const secondData = second
          ? [
              {
                x: this.toBillion(second.totalValue),
                progress: second.totalPreviousPercent,
              },
              {
                x: this.toBillion(second.exportValue),
                progress: second.exportValuePreviousPercent,
              },
              {
                x: this.toBillion(second.importValue),
                progress: second.importValuePreviousPercent,
              },
            ]
          : [];

        return {
          labels: ['Товарооборот', 'Экспорт', 'Импорт'],
          datasets: [
            {
              label: levelDevelopmentDataKeys[0] || '',
              data: firstData,
              backgroundColor: '#6cf9ff',
            },
            {
              label: levelDevelopmentDataKeys[1] || '',
              data: secondData,
              backgroundColor: '#17A1FA',
            },
          ],
          title: 'Млрд $',
        };
      },

      getPrepositionalCaseCountry() {
        if (this.infoAboutCommissionsData[0]?.country?.prepositionalCase) {
          return this.infoAboutCommissionsData[0].country.prepositionalCase.replace(/(^о )|(^об )/gm, '');
        } else {
          return '';
        }
      },
    },

    methods: {
      loadData() {
        const countryId = this.$route.query.countryId;

        API.getInfoAboutCommissions(countryId).then((response) => {
          const data = response?.data;

          if (data) {
            // Чистим внутри МПК участников, оставляем только сопредседателей с ИД 1
            this.infoAboutCommissionsData = data.map((mpk) => {
              mpk.mpkParticipants = mpk.mpkParticipants.filter(
                (cochairs) =>
                  cochairs?.participant?.participantCategoryId && cochairs.participant.participantCategoryId == 1,
              );
              // Россия на первое место в массиве представителей
              mpk.mpkParticipants.sort((a) => {
                if (a?.participant?.countryId == Constants.countryRussiaId) {
                  return -1;
                }
              });
              // Получаем достижения МПК и записываем их по idMPK
              const mpkId = this.getCommissionId(mpk);
              if (mpkId) {
                API.getAchievements(mpkId).then((res) => {
                  const data = res?.data;
                  if (data) {
                    this.achievements[mpkId] = data.achievement;
                  }
                });
              }
              return mpk;
            });
          }
        });

        // Проверка доступа к редактированию достижений МПК
        API.checkPermission()
          .then((response) => {
            this.canEditAchievements = response?.data ?? false;
          })
          .catch(() => {
            this.canEditAchievements = false;
          });

        // Рейтинг
        API.getMpkRatingForCountryDetailed(countryId).then((response) => {
          const data = response?.data;
          if (data) {
            this.place = data.summaryRatingPosition;
            this.totalValue = data.summaryRating ? data.summaryRating.toFixed(3) : '';
            this.mpkRatingForCountryDetailed = data;
            const ratingEfficiencyData = [
              // 1. Исполнительская дисциплина
              {
                rating: data.executiveDisciplineRatingPosition,
                modRating: '',
                name: 'Исполнительская дисциплина',
                value: data.executiveDisciplineRating,
                weight: 0.09,
                nameTab: 'uroven-intensivnosti-mpk',
                nameChart: 'executiveDisciplineRating',
              },
              // 2. Активность деятельности МПК
              {
                rating: data.mpkActivityRatingPosition,
                modRating: '',
                name: 'Активность деятельности МПК',
                value: data.mpkActivityRating,
                weight: 0.15,
                nameTab: 'uroven-intensivnosti-mpk',
                nameChart: 'mpkActivityRating',
              },
              // 3. Деятельность делового совета
              {
                rating: data.businessCouncilRatingPosition,
                modRating: '',
                name: 'Деятельность делового совета',
                value: data.businessCouncilRating,
                weight: 0.06,
                nameTab: 'uroven-intensivnosti-mpk',
                nameChart: 'businessCouncilRating',
              },
              // 4. Статус выполнения сквозных задач
              {
                rating: data.crossCuttingTasksRatingPosition,
                modRating: '',
                name: 'Статус выполнения сквозных задач',
                value: data.crossCuttingTasksRating,
                weight: 0.35,
                nameTab: 'skvoznye-zadachi',
                nameChart: 'crossCuttingTasksRating',
              },
              // 5. Снятие барьеров в торговле
              {
                rating: data.tradeBarriersRemovalRatingPosition,
                modRating: '',
                name: 'Снятие барьеров в торговле',
                value: data.tradeBarriersRemovalRating,
                weight: 0.1,
                nameTab: 'obem-snyatyh-barerov',
                nameChart: 'tradeBarriersRemovalRating',
              },
              // 6. Реализация двусторонних проектов
              {
                rating: data.bilateralProjectsRatingPosition,
                modRating: '',
                name: 'Реализация двусторонних проектов',
                value: data.bilateralProjectsRating,
                weight: 0.15,
                nameTab: 'dvustoronnie-proekty',
                nameChart: 'bilateralProjectsRating',
              },
              // 7. Уровень развития торговли
              {
                rating: data.tradeDevelopmentRatingPosition,
                modRating: '',
                name: 'Уровень развития торговли',
                value: data.tradeDevelopmentRating,
                weight: 0.1,
                nameTab: 'razvitie-torgovli',
                nameChart: 'tradeDevelopmentRating',
              },
            ];

            ratingEfficiencyData.forEach((a) => {
              if (a.rating == 0) {
                a.modRating = '';
              } else if (a.rating <= 15) {
                a.modRating = 'green';
              } else if (a.rating <= 30) {
                a.modRating = 'yellow';
              } else if (a.rating >= 31) {
                a.modRating = 'red';
              }
            });

            this.ratingEfficiencyData = ratingEfficiencyData;
          } else {
            this.place = '';
            this.ratingEfficiencyData = [];
          }
        });

        // Статус выполнения сквозных задач
        API.getCrossCuttingTasks(countryId).then((response) => {
          this.throughTasks = response.data ?? [];
        });

        // Барьеры
        API.getBarrierinfoDetailed(countryId).then((response) => {
          if (response?.data) {
            this.barrierinfo = response?.data;
          } else {
            this.barrierinfo = {};
          }
        });

        // Реализация двусторонних проектов
        API.getImplementationProjectsDetailed(countryId).then((response) => {
          if (response?.data) {
            this.implementationProjectsData = response.data;
          } else {
            this.implementationProjectsData = {};
          }
        });

        // Уровень развития торговли
        API.getLevelDevelopmentDetailed(countryId).then((response) => {
          if (response?.data) {
            this.levelDevelopmentData = response?.data;
          } else {
            this.levelDevelopmentData = {};
          }
        });
      },

      editAchievementsContentUpdate(content) {
        this.editAchievements.content = content;
      },

      editAchievementsSetup(mpkId) {
        const text = this.achievements[mpkId];
        if (mpkId) {
          this.editAchievements.active = true;
          this.editAchievements.mpkId = mpkId;
          this.editAchievements.content = this.achievements[mpkId];
        }

        if (text) {
          this.editAchievements.content = text;
        } else {
          this.editAchievements.content = [];
        }
      },

      editAchievementsSave() {
        if (this.editAchievements.mpkId) {
          API.editAchievements(this.editAchievements.mpkId, this.editAchievements.content).then(() => {
            this.achievements[this.editAchievements.mpkId] = this.editAchievements.content;
            this.editAchievements.active = false;
          });
        }
      },

      getCommissionId(item) {
        return item?.id ?? null;
      },

      needShowAchievements(item) {
        return this.canEditAchievements || this.achievements[this.getCommissionId(item)];
      },

      lineBreaks(str, spaceStep) {
        let stringArray = str.split(' ');
        let result = [];

        for (let index = 0; index < str.split(' ').length / spaceStep; index++) {
          result.push(stringArray.splice(0, spaceStep).join(' '));
        }

        return result;
      },

      selectRating(data) {
        this.$router.push({ name: 'efficiencyrating' });
        this.$emit('selectTab', data);
      },

      toBillion(num) {
        return Number((num / 1000000000).toFixed(1));
      },
    },
  };
</script>

<style lang="scss">
  .mpkRating__detailed {
    margin: 40px 0 0 0;
    max-width: 956px;
    width: 100%;

    @media (max-width: 650px) {
      margin: 0 0 25px 0;
    }

    &__titleBlock {
      display: flex;
      gap: 12px;
      flex-wrap: wrap;

      &__title {
        color: $base;
        font: $fira-24-M;
      }

      &__point {
        border-radius: 4px;
        background: $light-blue-2;
        padding: 4px 8px;
        color: $blue;
        font: $fira-16-M;
      }
    }

    &__structure-links {
      display: flex;
      flex-wrap: wrap;
      column-gap: 24px;
      row-gap: 12px;
    }

    &__header {
      margin-bottom: 16px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      row-gap: 16px;
      column-gap: 24px;
    }

    &__description {
      color: $base;
      font: $fira-16;
      margin: 0 0 40px 0;
      display: block;

      @media (max-width: 650px) {
        margin: 0 0 25px 0;
      }
    }

    &__achievement {
      margin: 0 0 40px 0;
      display: flex;
      flex-direction: column;

      @media (max-width: 650px) {
        margin: 0 0 25px 0;
      }

      &__titleBlock {
        display: flex;
        align-items: center;
        margin: 0 0 16px 0;

        &__title {
          color: $base;
          font: $fira-24-M;
        }

        &__editButton {
          width: 16px;
          height: 15px;
          margin-left: 16px;

          svg {
            path {
              stroke: $blue;
            }
          }

          &.active {
            svg {
              path {
                stroke: $blue-2;
              }
            }
          }
        }
      }

      &__text {
        * {
          font: $fira-16;
        }

        ul {
          list-style: disc;
          padding: 0 0 0 14px;

          li {
            color: $base;

            &:not(:last-child) {
              margin: 0 0 16px 0;
            }
          }
        }
      }
    }

    &__ratingEfficiency {
      display: flex;
      flex-direction: column;
      margin: 0 0 40px 0;
      align-items: flex-start;

      @media (max-width: 650px) {
        margin: 0 0 25px 0;
      }

      &__titleBlock {
        display: flex;
        gap: 8px;
        margin: 0 0 16px 0;
        position: relative;
        flex-wrap: wrap;

        &__title {
          color: $base;
          font: $fira-24-M;
        }

        &__helpButton {
          .tooltip-component__content {
            .mpkRating__detailed__ratingEfficiency__titleBlock__helpButton__description {
              color: $base;
              font: $fira-14;
              margin: 0 0 16px 0;
              display: block;
            }

            .mpkRating__detailed__ratingEfficiency__titleBlock__helpButton__infographics {
              color: $base;
              font: $fira-14;

              .green {
                color: $approval-text;
                font: $fira-14-M;
              }

              .yellow {
                color: #f4b700;
                font: $fira-14-M;
              }

              .red {
                color: #f66;
                font: $fira-14-M;
              }
            }

            @media (max-width: 580px) {
              left: 0 !important;
            }
          }
        }
      }

      &__infographics {
        display: flex;
        width: 100%;
        margin: 0 0 16px 0;

        &__valueBlock {
          color: $base;
          font: $fira-20-M;

          &__value {
            background-image: linear-gradient(90deg, $blue 0%, $green 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font: $fira-20-M;
            margin: 0 0 0 8px;
          }
        }

        &__weight {
          margin: 0 0 0 auto;
          color: $green;
          font: $fira-20-M;
        }
      }

      &__chartBar {
        width: 100%;

        .horizontalBarCustom__barBlock {
          cursor: pointer;
        }
      }
    }

    &__statusTasks {
      width: 100%;
      margin: 0 0 40px 0;

      @media (max-width: 650px) {
        margin: 0 0 25px 0;
      }

      &__titleBlock {
        margin: 0 0 24px 0;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        align-items: center;
        justify-content: space-between;

        &__title {
          color: $base;
          font: $fira-24-M;
        }

        &__moreButton {
          padding: 0;

          &__icon {
            path {
              stroke: $blue;
            }
          }
        }
      }

      &__accordionBlock {
        display: flex;
        flex-direction: column;
        gap: 40px;

        &__accordionCategory {
          display: flex;
          flex-direction: column;
          gap: 16px;

          &__title {
            color: $base;
            font: $fira-20-M;
            margin: 0 0 8px 0;
          }
        }
      }
    }

    &__implementationProjects {
      width: 100%;
      margin: 0 0 40px 0;

      @media (max-width: 650px) {
        margin: 0 0 25px 0;
      }

      &__titleBlock {
        margin: 0 0 24px 0;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        column-gap: 24px;
        align-items: center;
        justify-content: space-between;

        @media (max-width: $laptop) {
          margin-bottom: 16px;
        }

        &__title {
          color: $base;
          font: $fira-24-M;
        }

        &__moreButton {
          padding: 0;

          &__icon {
            path {
              stroke: $blue;
            }
          }
        }
      }

      &__chart {
        border-radius: 4px;
        border: 1px solid $grey;
        background: $light-grey;
        padding: 40px 60px;

        @media (max-width: 650px) {
          padding: 0;
        }
      }
    }

    &__levelDevelopment {
      width: 100%;
      margin: 0 0 20px 0;

      &__title {
        color: $base;
        font: $fira-24-M;
        display: block;
      }

      &__header {
        margin-bottom: 24px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        row-gap: 12px;
        column-gap: 24px;

        @media (max-width: $laptop) {
          margin-bottom: 16px;
        }
      }

      &__chart {
        border-radius: 4px;
        border: 1px solid $grey;
        background: $light-grey;
        padding: 40px 60px;

        @media (max-width: 650px) {
          padding: 0;
        }
      }
    }

    .body__wrapper {
      flex-wrap: nowrap;

      @media (max-width: $laptop-2) {
        flex-wrap: wrap;
      }
    }

    .body__description {
      width: 100%;
      max-width: 50%;

      @media (max-width: $laptop-2) {
        max-width: 100%;
      }
    }

    .body__header {
      @media (max-width: $laptop-2) {
        width: 100%;
      }
    }

    .header__item-header {
      max-width: 80% !important;
    }
  }

  .mpkRating__editAchievements {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__navigation {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  .mpkRating_exportPdf {
    .mpkRating__detailed__titleBlock__point {
      line-height: 10px;
      margin: -5px 0 0px 0;
      position: relative;
      top: 15px;
    }

    .collapse-component {
      .collapse-component__header {
        .header__item-status {
          line-height: 10px;
          margin: -10px 10px 0px 0;
          position: relative;
          top: 15px;
          height: 40px;
        }

        .header__item-header {
          margin: -12px 0 0 0;
          padding: 8px 0;
        }
      }

      .body {
        * {
          line-height: 40px;
        }
      }
    }

    .mpkRating__detailed__achievement__text li::marker {
      line-height: 1px;
    }

    .mpkRating__detailed__removingExports__block {
      background: $light-grey-2;
      box-shadow: none;
    }

    .mpkRating__detailed__statusTasks__titleBlock__moreButton__icon {
      margin: 6px 0 0 0;
      height: 20px;
    }

    .mpkRating__detailed__implementationProjects__titleBlock__moreButton__icon {
      margin: 6px 0 0 0;
      height: 20px;
    }

    .mpkRating__detailed__ratingEfficiency {
      &__infographics__valueBlock__value {
        background-image: none;
        background-clip: initial;
        color: $blue;
        -webkit-text-fill-color: initial;
      }

      .horizontalBarCustom__barBlock__label {
        height: 30px;
      }
    }
  }
</style>
