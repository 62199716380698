<template>
  <component :is="component" class="link">
    <slot></slot>
  </component>
</template>

<script>
  import { RouterLink } from 'vue-router';

  export default {
    name: 'CustomLink',
    props: {
      component: {
        type: [String, Object],
        default: RouterLink,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .link {
    all: unset;
    font: $fira-16-M;
    color: $blue;
    cursor: pointer;

    &:hover {
      color: $blue-4;
    }
  }
</style>
