import axios from 'axios';

export default {
  checkPermission: function () {
    return axios.get('/api/permission/check', {
      params: {
        permId: '164',
        entityType: 'null',
        entityId: '0',
      },
    });
  },

  getInfoAboutCommissions: function (countryId) {
    return axios.get('/api/MpkRating/GetInfoAboutCommissions', {
      params: { countryId: countryId },
    });
  },

  getAchievements: function (mpkId) {
    return axios.get('/api/MpkRating/GetAchievements', {
      params: { mpkId: mpkId },
    });
  },

  editAchievements: function (mpkId, achievements) {
    return axios.post('/api/MpkRating/EditAchievements', {
      mpkId: mpkId,
      achievements: achievements,
    });
  },

  getCountryList: function () {
    return axios.get('/api/MpkRating/GetCountryList');
  },

  getMpkRatingForCountries: function () {
    return axios.get('/api/mpkrating/calculateratingsforallcountries');
  },

  getMpkRatingForCountryDetailed: function (countryId) {
    return axios.get('/api/mpkrating/calculateratingsforcountry', {
      params: { countryId: countryId },
    });
  },

  getCrossCuttingTasks: function (countryId) {
    return axios.get('/api/crosscuttingtask/tasksforcochairmanmpk', {
      params: { countryId: countryId },
    });
  },

  getBarrierinfoDetailed: function (countryId) {
    return axios.get('/api/mpkrating/getbarrierinfo', {
      params: { countryId: countryId },
    });
  },

  getImplementationProjectsDetailed: function (countryId) {
    return axios.get('/api/mpkrating/projectdevelopmentchart', {
      params: { countryId: countryId },
    });
  },

  getLevelDevelopmentDetailed: function (countryId) {
    return axios.get('/api/mpkrating/tradedevelopmentchart', {
      params: { countryId: countryId },
    });
  },
};
