<template>
  <Tooltip modContent="width-407" position="bottom" nameIcon="tooltip-info" :triggers="['focus', 'click', 'touch']">
    <h5 class="tooltip__title">Комментарий</h5>
    <p class="tooltip__text">
      {{ comment }}
    </p>
  </Tooltip>
</template>

<script>
  import Tooltip from '@/common/ui/Tooltip.vue';

  export default {
    components: {
      Tooltip,
    },
    props: {
      comment: {
        type: String,
        required: true,
      },
    },
  };
</script>

<style lang="scss">
  .tooltip {
    &__title {
      font: $fira-14-M;
    }

    &__text {
      font: $fira-14;
      color: $light-grey-12;
    }
  }
</style>
